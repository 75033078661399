import { StyledNavigationItem } from 'baseui/header-navigation'
import { LinkProps } from 'baseui/link'
import { useState } from 'react'

import { Theme } from '../styles/themes/default'

import { NavLink } from './styled-components'

export interface NavItemProps extends LinkProps {
	isActive: boolean
}

const NavItem: React.FC<NavItemProps> = ({ isActive, children, ...props }) => {
	const [isHovered, setHovered] = useState<boolean>(false)
	return (
		<StyledNavigationItem
			$style={({ $theme }: { $theme: Theme }) => ({
				marginTop: $theme.sizing.scale800,
				paddingRight: $theme.sizing.scale800,
				[$theme.mediaQuery.medium]: {
					marginTop: 0,
					paddingRight: 0,
				},
			})}
		>
			<NavLink
				$isActive={isActive}
				$isHovered={isHovered}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				{...props}
			>
				{children}
			</NavLink>
		</StyledNavigationItem>
	)
}

export default NavItem
