import { FlexGrid } from 'baseui/flex-grid'
import { Notification, KIND } from 'baseui/notification'
import { StyledSpinnerNext } from 'baseui/spinner'

export const FormError: React.FC = ({ children }) => (
	<Notification
		kind={KIND.negative}
		overrides={{
			Body: {
				style: ({ $theme }) => ({
					position: 'relative',
					width: 'auto',
					borderRadius: $theme.borders.radius100,
					...$theme.typography.ParagraphMedium,
				}),
			},
			CloseIcon: {
				style: ({ $theme }) => ({
					backgroundColor: $theme.colors.notificationNegativeBackground,
				}),
			},
		}}
		closeable
	>
		{children}
	</Notification>
)

export const FormSuccess: React.FC = ({ children }) => (
	<Notification
		kind={KIND.positive}
		overrides={{
			Body: {
				style: ({ $theme }) => ({
					position: 'relative',
					width: 'auto',
					borderRadius: $theme.borders.radius100,
					...$theme.typography.ParagraphMedium,
				}),
			},
		}}
		closeable
	>
		{children}
	</Notification>
)

export const Spinner: React.FC = ({ children }) => (
	<FlexGrid
		flexGridColumnCount={1}
		height="100%"
		justifyContent="center"
		alignItems="center"
	>
		<StyledSpinnerNext>{children}</StyledSpinnerNext>
	</FlexGrid>
)
