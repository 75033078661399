import { makeCMSComponent } from '../CMSComponent'

import JoinWaitlistFormModal from './JoinWaitlistFormModal'

export const JoinWaitlistModal = makeCMSComponent(
	{
		tag: 'JoinWaitlistFormModal',
		label: 'Modal - Join waitlist',
		fields: {
			name: {
				label: 'Name',
				widget: 'string',
			},
			label: {
				label: 'Label',
				widget: 'string',
			},
			title: {
				label: 'Modal Heading title',
				widget: 'string',
			},
			subtitle: {
				label: 'Modal Heading subtitle',
				widget: 'string',
			},
		},
	},
	JoinWaitlistFormModal
)
