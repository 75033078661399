import React from 'react'

import { Checkbox } from '../../Checkbox'
import { Radio } from '../../Radio'

const typeToFormComponentMap: Record<
	'multiple-choice' | 'checkboxes',
	React.ComponentType<any>
> = {
	'multiple-choice': Radio,
	checkboxes: Checkbox,
}

export interface FormAnswerParams {
	label: string
	value: string
	type?: 'checkboxes' | 'multiple-choice'
}

const FormAnswer: React.FC<FormAnswerParams> = ({
	value,
	type = 'multiple-choice',
	label,
	...props
}) => {
	const FormComponent = typeToFormComponentMap[type]

	return (
		<FormComponent value={value} {...props}>
			{label}
		</FormComponent>
	)
}

export default FormAnswer
