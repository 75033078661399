import { useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'

import { EventNames, SectionViewedEvent, useTracking } from '../analytics'

interface UseTrackSectionViewedParams {
	sectionName: string
	intersectionObserverThreshold?: number
}

interface UseTrackSectionViewedValue {
	ref(node?: Element | null | undefined): void
}

function useTrackSectionViewed({
	sectionName,
	intersectionObserverThreshold = 0.5,
}: UseTrackSectionViewedParams): UseTrackSectionViewedValue {
	const { ref, inView } = useInView({
		threshold: intersectionObserverThreshold,
	})

	const { trackEvent } = useTracking({
		once: [EventNames.SectionViewed],
	})

	useEffect(() => {
		if (inView) {
			const eventData: SectionViewedEvent = {
				section_name: sectionName,
			}

			trackEvent(EventNames.SectionViewed, eventData)
		}
	}, [inView, trackEvent, sectionName])

	return useMemo(() => ({ ref }), [ref])
}

export default useTrackSectionViewed
