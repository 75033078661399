import { makeCMSComponent } from '../CMSComponent'

import { default as VideoSectionComponent } from './VideoSection'

export const VideoSection = makeCMSComponent(
	{
		tag: 'VideoSection',
		label: 'Video section in our-app page',
		fields: {
			title: {
				label: 'Title',
				widget: 'string',
			},
		},
	},
	VideoSectionComponent
)
