import { makeCMSComponent } from '../CMSComponent'

import JoinWaitlistFollowUpFormModal from './JoinWaitlistFollowUpModal'
import ModalHeaderComponent from './ModalHeader'

export const JoinWaitlistFollowUpModal = makeCMSComponent(
	{
		tag: 'JoinWaitlistFollowUpModal',
		label: 'Modal - Join waitlist follow up questions',
		fields: {
			name: {
				label: 'Name',
				widget: 'string',
			},
			subtitle: {
				label: 'Modal Heading subtitle',
				widget: 'string',
			},
		},
	},
	JoinWaitlistFollowUpFormModal
)

export const WaitlistFollowUpModalHeader = makeCMSComponent(
	{
		tag: 'WaitlistFollowUpModalHeader',
		label: 'Modal Header - Join waitlist follow up questions',
		fields: {},
	},
	ModalHeaderComponent
)
