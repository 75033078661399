import { map } from 'lodash'
import type {
	EditorComponentOptions,
	EditorComponentField,
} from 'netlify-cms-core'
import type { PropsWithChildren, ReactElement } from 'react'

type CMSComponentFieldsInputMapEntry = {
	label: string
	widget: 'string' | 'text' | 'mdx' | 'image' | 'file' | 'select' | 'boolean'
}

type CMSComponentFieldInputMap = Record<string, CMSComponentFieldsInputMapEntry>

function cmsComponentFieldsInputMapToEditorComponentFields(
	input: CMSComponentFieldInputMap
): EditorComponentField[] {
	return map(input, (value, key) => ({
		...value,
		name: key,
	}))
}

type WidgetToPropType = {
	string: string
	mdx: string
	image: string
	text: string
	file: string
	select: string
	boolean: boolean
}

type FieldsToProps<T extends CMSComponentFieldInputMap> = {
	[K in keyof T]: WidgetToPropType[T[K]['widget']]
}

interface Renderable<P = {}> {
	(props: PropsWithChildren<P>, context?: any): ReactElement<any, any> | null
	displayName?: string
}

export type CMSComponentInput<Fields extends CMSComponentFieldInputMap> = [
	{
		tag: string
		label: string
		fields: Fields
	},
	Renderable<FieldsToProps<Fields>>
]

export interface CMSComponent<Fields extends CMSComponentFieldInputMap>
	extends Renderable<FieldsToProps<Fields>>,
		EditorComponentOptions {
	// netlfy-cms-core is having only the string version here, so we widen to allow for having react components
	toPreview(data: any): any
}

export function makeCMSComponent<Fields extends CMSComponentFieldInputMap>(
	...args: CMSComponentInput<Fields>
): CMSComponent<Fields> {
	type Props = FieldsToProps<Fields>
	const [editing, Component] = args
	const id = editing.tag
	const fields = cmsComponentFieldsInputMapToEditorComponentFields(
		editing.fields
	)
	const mdxElements = fields.filter(field => field.widget === 'mdx')
	const otherElements = fields.filter(field => field.widget !== 'mdx')
	if (mdxElements.length > 1) {
		throw new Error(`component ${id} can only have one mdx field!`)
	}
	const hasBody = mdxElements.length > 0

	const allChars = `([\\s\\S]*?)`

	const pattern = new RegExp(
		`<${id}${allChars}>${hasBody ? allChars : ``}</${id}>`
	)
	// console.log(editing.tag, pattern)

	function toBlock(data: Props): string {
		// console.log('toBlock', editing.tag)
		return `<${id} ${otherElements
			.map(
				element =>
					`${element.name}={${JSON.stringify(data[element.name] ?? null)}}`
			)
			.join(' ')}>${mdxElements
			.map(element => data[element.name])
			.join('')}</${id}>`
	}

	function fromBlock(match: RegExpMatchArray): Props {
		// const all = match[0]
		const props = match[1]
		const body = match[2]

		// console.log(editing.tag)
		// console.log(all)
		// console.log(props)
		// console.log(body)
		// console.log(pattern)

		const result: any = {}
		if (hasBody) {
			// remove tabs, otherwise netlify will trigger code blocks
			// eslint-disable-next-line no-control-regex
			result[mdxElements[0].name] = body.replace(/	/g, '')
		}

		const attributePair = /(\S+)={(.*?)}/g
		props.match(attributePair)?.forEach(rawPair => {
			const pair = attributePair.exec(rawPair)
			if (pair) {
				const key = pair[1]
				let value = pair[2]
				// prettier converts " to ', so fix these for manually edited mdx files
				value = value.replace(/^'/, '"').replace(/'$/, '"')
				result[key] = JSON.parse(value)
			}
			attributePair.lastIndex = 0
		})
		return result as Props
	}

	// cast is needed due to some weirdness in react prop types validation maps
	return Object.assign(Component, editing, {
		id,
		fields,
		pattern,
		toBlock,
		fromBlock,
		toPreview(data: any) {
			// or : FieldsToProps<Fields>
			// const Component = component
			// return <Component {...data} />
			return (
				<p>
					You shouldn't see this, as this component should be rendered in an MDX
					context and not directly
				</p>
			)
		},
	})
}

export type PropsFromCMSComponent<T extends CMSComponent<any>> =
	T extends CMSComponent<infer Fields> ? FieldsToProps<Fields> : {}
