// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-goodbye-zwerl-mdx": () => import("./../../../src/pages/goodbye-zwerl.mdx" /* webpackChunkName: "component---src-pages-goodbye-zwerl-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-our-app-mdx": () => import("./../../../src/pages/our-app.mdx" /* webpackChunkName: "component---src-pages-our-app-mdx" */)
}

