import styled from 'styled-components'

import { Button, ButtonProps, KIND, SHAPE } from '../Button'
import Cross from '../SVGS/Cross'

const CloseButtonContainer = styled.div`
	position: absolute;
	display: flex;
	top: ${({ theme }) => theme.sizing.scale800};
	right: ${({ theme }) => theme.sizing.scale800};
`

const ButtonResponsive = styled(Button)`
	width: 32px;
	height: 32px;

	${({ theme }) => theme.mediaQuery.medium} {
		width: 48px;
		height: 48px;
	}
`

const CrossIconWrapper = styled.div`
	width: 16px;
	height: 16px;

	${({ theme }) => theme.mediaQuery.medium} {
		width: 21px;
		height: 21px;
	}

	svg {
		display: block;
	}
`

const CloseButton: React.FC<ButtonProps> = ({ onClick }) => (
	<CloseButtonContainer>
		<ButtonResponsive
			kind={KIND.tertiary}
			shape={SHAPE.circle}
			size="default"
			onClick={onClick}
		>
			<CrossIconWrapper title="Close">
				<Cross />
			</CrossIconWrapper>
		</ButtonResponsive>
	</CloseButtonContainer>
)

export default CloseButton
