import {
	createContext,
	useContext,
	useState,
	Dispatch,
	SetStateAction,
} from 'react'

interface TwitterBannerContextValue {
	isShown?: boolean
	setShown: Dispatch<SetStateAction<boolean>>
}
const TwitterBannerContext = createContext<TwitterBannerContextValue>({
	setShown() {},
})

export const TwitterBannerContextProvider: React.FC = ({ children }) => {
	const [isShown, setShown] = useState<boolean>(false)

	return (
		<TwitterBannerContext.Provider value={{ isShown, setShown }}>
			{children}
		</TwitterBannerContext.Provider>
	)
}

export const useTwitterBanner = () => {
	const context = useContext(TwitterBannerContext)
	if (context === undefined) {
		throw new Error(
			'useTwitterBanner must be used within a UseTwitterContextProvider'
		)
	}

	return context
}
