import { withStyle } from 'baseui'

import { ModalHeader as DefaultHeader } from '../Modal'

export const SuccessModalHeader = withStyle(DefaultHeader, ({ $theme }) => ({
	...$theme.typography.HeadingLarge,
	textAlign: 'center',
	maxWidth: '600px',
	marginTop: '0',
	marginLeft: 'auto',
	marginRight: 'auto',
	[$theme.mediaQuery.medium]: {
		...$theme.typography.HeadingXLarge,
	},
}))

export default SuccessModalHeader
