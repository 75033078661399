import { ButtonProps as BaseUIButtonProps } from 'baseui/button'
import { SHAPE, SIZE } from 'baseui/button'
import { StyleObject } from 'styletron-react'

import { Theme } from '../styles/themes/default'

interface ButtonProps extends BaseUIButtonProps {}

function getPaddingStyles({
	$theme,
	$size,
	$shape,
}: {
	$theme: Theme
	$size: ButtonProps['size']
	$shape: ButtonProps['shape']
}) {
	// TODO: Find out how the underlying Button deals with this as
	// it does not have any specific logic and yet it does not add padding
	if ($shape === SHAPE.circle) {
		if ($size === SIZE.compact) {
			return {
				width: '42px',
				height: '42px',
			}
		}

		return {}
	}

	const iconShape = $shape === SHAPE.square || $shape === SHAPE.round

	switch ($size) {
		case SIZE.mini:
			return {
				paddingTop: $theme.sizing.scale200,
				paddingBottom: $theme.sizing.scale200,
				paddingLeft: iconShape
					? $theme.sizing.scale200
					: $theme.sizing.scale300,
				paddingRight: iconShape
					? $theme.sizing.scale200
					: $theme.sizing.scale300,
			}
		case SIZE.compact:
			return {
				paddingTop: $theme.sizing.scale500,
				paddingBottom: $theme.sizing.scale500,
				paddingLeft: iconShape
					? $theme.sizing.scale500
					: $theme.sizing.scale600,
				paddingRight: iconShape
					? $theme.sizing.scale500
					: $theme.sizing.scale600,
				...$theme.typography.font250,
				[$theme.mediaQuery.medium]: {
					...$theme.typography.font300,
				},
			}
		case SIZE.large:
			return {
				paddingTop: $theme.sizing.scale600,
				paddingBottom: $theme.sizing.scale600,
				paddingLeft: iconShape
					? $theme.sizing.scale1000
					: $theme.sizing.scale900,
				paddingRight: iconShape
					? $theme.sizing.scale1000
					: $theme.sizing.scale900,
				...$theme.typography.font350,
				[$theme.mediaQuery.medium]: {
					paddingTop: $theme.sizing.scale800,
					paddingBottom: $theme.sizing.scale800,
					paddingLeft: iconShape
						? $theme.sizing.scale1800
						: $theme.sizing.scale1800,
					paddingRight: iconShape
						? $theme.sizing.scale1800
						: $theme.sizing.scale1800,
					...$theme.typography.font450,
				},
			}
		default:
			return {
				paddingTop: $theme.sizing.scale550,
				paddingBottom: $theme.sizing.scale550,
				paddingLeft: iconShape
					? $theme.sizing.scale800
					: $theme.sizing.scale900,
				paddingRight: iconShape
					? $theme.sizing.scale800
					: $theme.sizing.scale900,
				...$theme.typography.font300,
				[$theme.mediaQuery.large]: {
					...$theme.typography.font350,
				},
			}
	}
}

export function overrideStyles({
	$theme,
	$shape,
	$size,
}: {
	$theme: Theme
	$shape: ButtonProps['shape']
	$size: ButtonProps['size']
}) {
	let overridenStyles: StyleObject = {
		borderColor: $theme.colors.border,
		borderTopWidth: '1px',
		borderBottomWidth: '1px',
		borderLeftWidth: '1px',
		borderRightWidth: '1px',
		borderBottomStyle: 'solid',
		borderTopStyle: 'solid',
		borderLeftStyle: 'solid',
		borderRightStyle: 'solid',
		...getPaddingStyles({ $theme, $shape, $size }),
	}

	// Only update border radius on pill shape
	if ($shape === SHAPE.pill) {
		overridenStyles = {
			...overridenStyles,
			borderBottomLeftRadius: $theme.borders.radius500,
			borderBottomRightRadius: $theme.borders.radius500,
			borderTopRightRadius: $theme.borders.radius500,
			borderTopLeftRadius: $theme.borders.radius500,
		}
	}

	return overridenStyles
}
