import { FC, useState, useEffect } from 'react'

import { SIZE, SHAPE } from '../Button'
import useTrackSectionViewed from '../hooks/useTrackSectionViewed'
import getConfig from '../infra/config/config'
import { ToggleButton } from '../ToggleButton'
import { H2 } from '../Typography'

import { SectionContainer, EmptySectionContainer } from './styled-components'

export interface JoinWaitlistBannerProps {
	ctaText: string
}

const JoinWaitlistBanner: FC<JoinWaitlistBannerProps> = ({
	ctaText,
	children,
}) => {
	const { ref } = useTrackSectionViewed({
		sectionName: 'our-app/CTA',
	})

	const [shouldShowBanner, setShowBanner] = useState<boolean>(false)

	useEffect(() => {
		setShowBanner(!getConfig().hideAuthLinks)
	}, [setShowBanner])

	if (!shouldShowBanner) {
		return <EmptySectionContainer />
	}
	return (
		<SectionContainer ref={ref}>
			<H2
				overrides={{
					Block: {
						style({ $theme }) {
							return {
								...$theme.typography.HeadingMedium,
								maxWidth: '800px',
								marginLeft: 'auto',
								marginRight: 'auto',
								marginTop: '0',
								[$theme.mediaQuery.medium]: {
									...$theme.typography.HeadingXLarge,
								},
								[$theme.mediaQuery.large]: {
									...$theme.typography.HeadingXXLarge,
									maxWidth: '900px',
								},
							}
						},
					},
				}}
			>
				{children}
			</H2>
			<ToggleButton
				data-tracking
				size={SIZE.large}
				shape={SHAPE.pill}
				targetName="join-waitlist"
			>
				{ctaText}
			</ToggleButton>
		</SectionContainer>
	)
}

export default JoinWaitlistBanner
