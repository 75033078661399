import { RadioGroup as BaseUIRadioGroup } from 'baseui/radio'
import { Field, FieldInputProps, FormikProps } from 'formik'
import { useMemo } from 'react'

interface RadioGroupInternalProps {
	field: FieldInputProps<string>
	form: FormikProps<any>
	required?: boolean
	label?: string
	caption?: string
}
function createValidator(required?: boolean): (value: any) => void | string {
	// Returns a validator which always return undefined (always valid)
	if (!required) {
		return (_: any) => undefined
	}

	const validate = (value: any) =>
		required && !value ? 'value is required' : undefined

	return validate
}
const RadioGroupInternalComponent: React.FC<RadioGroupInternalProps> = ({
	field,
	form,
	children,
	required = false,
	...props
}) => (
	<BaseUIRadioGroup
		{...field}
		{...props}
		required={required}
		value={field.value || ''}
		error={!!form.errors[field.name] && !!form.touched[field.name]}
	>
		{children}
	</BaseUIRadioGroup>
)

export interface RadioGroupProps {
	name: string
	caption?: string
	label?: string
	required?: boolean
	errorMessage?: string
}

const RadioGroup: React.FC<RadioGroupProps> = ({
	required,
	errorMessage = 'please select an option',
	...props
}) => {
	const validate = useMemo(() => createValidator(required), [required])
	return (
		<Field
			required={required}
			validate={validate}
			component={RadioGroupInternalComponent}
			{...props}
		/>
	)
}

export default RadioGroup
