import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

import { useTwitterBanner } from '../context/TwitterBannerContext'
import getConfig from '../infra/config/config'

import { TwitterFullPageBanner } from './TwitterFullPageBanner'
import { TwitterStickyBanner } from './TwitterStickyBanner'

const ScrollingTwitterBannerContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transform: translateY(0);
	z-index: 100;
	will-change: transform;
`

export interface TwitterBannerProps {
	readWhyCtaUrl: string
}

const TwitterBanner: React.FC<TwitterBannerProps> = ({ readWhyCtaUrl }) => {
	const [bannerTransformY, setBannerTransformY] = useState<string>('0')
	const shouldShowBanner = getConfig().unlaunchVariationEnabled
	const [hideInitialBanner, setHideInitialBanner] = useState<boolean>(false)
	const { setShown, isShown } = useTwitterBanner()
	const { ref, inView } = useInView({
		threshold: 0,
	})

	useEffect(() => {
		const handleScroll = () => {
			if (inView && !hideInitialBanner) {
				const currentPageScrollY = window.pageYOffset
				setBannerTransformY(`${-currentPageScrollY * 2}px`)
			} else {
				setHideInitialBanner(true)
			}
		}

		window.addEventListener('scroll', handleScroll)

		return () => window.removeEventListener('scroll', handleScroll)
	}, [
		setBannerTransformY,
		inView,
		setShown,
		isShown,
		setHideInitialBanner,
		hideInitialBanner,
	])

	useEffect(() => {
		setShown(!!shouldShowBanner && !hideInitialBanner && inView)
	}, [setShown, shouldShowBanner, hideInitialBanner, inView])

	// We don't show the banner at all if initially page has any scroll
	useEffect(() => {
		if (window.pageYOffset > 0) {
			setHideInitialBanner(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!shouldShowBanner) {
		return null
	}

	return (
		<>
			{!hideInitialBanner && (
				<ScrollingTwitterBannerContainer
					style={{ transform: `translateY(${bannerTransformY})` }}
					ref={ref}
				>
					<TwitterFullPageBanner ctaText="Learn more" ctaUrl={readWhyCtaUrl} />
				</ScrollingTwitterBannerContainer>
			)}
			<TwitterStickyBanner ctaText="Learn more" ctaUrl={readWhyCtaUrl} />
		</>
	)
}

export default TwitterBanner
