import { makeCMSComponent } from '../CMSComponent'

import { default as JoinWaitlistFollowUpSuccessModalComponent } from './JoinWaitlistFollowUpSuccessModal'

export const JoinWaitlistFollowUpSuccessModal = makeCMSComponent(
	{
		tag: 'JoinWaitlistFollowUpSuccessModal',
		label:
			'JoinWaitlistFollowUpSuccessModal - shown after second waitlist follow up form submit',
		fields: {
			name: {
				label: 'Name',
				widget: 'string',
			},
			subtitle: {
				label: 'Modal Heading subtitle',
				widget: 'string',
			},
			buttonText: {
				label: 'Modal button text',
				widget: 'string',
			},
		},
	},
	JoinWaitlistFollowUpSuccessModalComponent
)
