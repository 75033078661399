import { HeaderNavigation } from 'baseui/header-navigation'
import React, { useEffect, useState } from 'react'

import getConfig from '../infra/config/config'

import DesktopNavbar from './DesktopNavbar'
import MobileNavbar from './MobileNavbar'
import { headerNavigationOverrides } from './style-override'

const AppNavBar: React.FC = ({ children }) => {
	const [signInUrl, setSignInUrl] = useState<string>('')

	useEffect(() => {
		const url = getConfig().signInUrl!
		setSignInUrl(url)
	}, [])

	return (
		<HeaderNavigation overrides={headerNavigationOverrides}>
			<DesktopNavbar signInUrl={signInUrl}>{children}</DesktopNavbar>
			<MobileNavbar signInUrl={signInUrl}>{children}</MobileNavbar>
		</HeaderNavigation>
	)
}

export default AppNavBar
