import React, { useEffect, useState } from 'react'

import useTrackSectionViewed from '../hooks/useTrackSectionViewed'
import getConfig from '../infra/config/config'
import { ParagraphLarge } from '../Typography'

import {
	FooterContainer,
	FooterContent,
	FooterNormalLinks,
	FooterSocialLinks,
	NormalLink,
	SocialLink,
} from './styled-components'

const Footer: React.FC = () => {
	const { ref } = useTrackSectionViewed({
		sectionName: 'Footer',
		intersectionObserverThreshold: 0.25,
	})

	const [showCareersLink, setShowCareersLink] = useState<boolean>(false)
	const [showContactUsLink, setShowContactsLink] = useState<boolean>(false)

	useEffect(() => {
		setShowCareersLink(!getConfig().unlaunchVariationEnabled)
		setShowContactsLink(!!getConfig().unlaunchVariationEnabled)
	}, [setShowCareersLink, setShowContactsLink])

	return (
		<FooterContainer ref={ref}>
			<FooterContent>
				<ParagraphLarge
					overrides={{
						Block: {
							style: ({ $theme }) => ({
								marginTop: 0,
								marginBottom: 0,
								textAlign: 'center',
								...$theme.typography.ParagraphMedium,
								[$theme.mediaQuery.medium]: {
									...$theme.typography.ParagraphLarge,
								},
							}),
						},
					}}
				>
					Let's be friends!
				</ParagraphLarge>
				<FooterSocialLinks>
					<SocialLink
						data-tracking
						text="Twitter"
						url="https://twitter.com/spheremessenger"
						target="_blank"
					/>
					<SocialLink
						data-tracking
						text="Instagram"
						url="https://instagram.com/spheremessenger"
						target="_blank"
					/>
					<SocialLink
						data-tracking
						text="LinkedIn"
						url="https://linkedin.com/company/spheremessenger"
						target="_blank"
					/>
					<SocialLink
						data-tracking
						text="Medium"
						url="https://medium.com/spheremessenger"
						target="_blank"
					/>
				</FooterSocialLinks>
				<FooterNormalLinks>
					<NormalLink
						data-tracking
						text="Terms and Conditions"
						url="https://sphere.me/terms"
						target="_blank"
					></NormalLink>

					<NormalLink
						data-tracking
						text={'Privacy Policy'}
						url={'https://sphere.me/privacy'}
						target={'_blank'}
					></NormalLink>
					{showCareersLink && (
						<NormalLink
							data-tracking
							text={'Careers'}
							url={'https://sphere.me/careers'}
							target={'_blank'}
						></NormalLink>
					)}
					{showContactUsLink && (
						<NormalLink
							data-tracking
							text={'Contact us'}
							url={'mailto:contact@sphere.me'}
						></NormalLink>
					)}
				</FooterNormalLinks>
			</FooterContent>
		</FooterContainer>
	)
}
export default Footer
