import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { SegmentClient } from '@sphere/segment-proxy'

import getConfig from './infra/config/config'

export const SegmentTrackerLoader: React.FC = () => {
	const [segmentClient, setClient] = useState<SegmentClient>()

	useEffect(() => {
		if (!segmentClient) {
			const config = getConfig()

			if (config.tracking) {
				// Initialise and load segment proxy client
				setClient(new SegmentClient(config.tracking, uuidv4()))
			}
		}
	}, [segmentClient])

	return null
}
