import { makeCMSComponent } from '../CMSComponent'

import { default as TwitterBannerComponent } from './TwitterBanner'

export const TwitterBanner = makeCMSComponent(
	{
		tag: 'TwitterBanner',
		label: 'TwitterBanner section usually used at the top of the page',
		fields: {
			readWhyCtaUrl: {
				label: 'Url to medium blog',
				widget: 'string',
			},
		},
	},
	TwitterBannerComponent
)
