import { RadioOverrides } from 'baseui/radio'
import { StyleObject } from 'styletron-react'

// Below functions are identical but we might want more flexibility
// with styles in the future that is why inner and outer radio styles
// are decoupled
function getOuterColor(props: any): string {
	const {
		$theme: { colors },
		$isActive,
		$checked,
		$isHovered,
		$error,
	} = props

	if (!$checked) {
		if ($error) {
			return $isActive || $isHovered
				? colors.tickFillErrorHover
				: colors.tickFillError
		}
		return $isActive || $isHovered ? colors.mono200 : colors.backgroundPrimary
	} else {
		return $isActive || $isHovered
			? colors.tickFillSelectedHover
			: colors.tickFillSelected
	}
}

function getInnerColor(props: any): string {
	const {
		$theme: { colors },
		$isActive,
		$checked,
		$isHovered,
		$error,
	} = props

	if (!$checked) {
		if ($error) {
			return $isActive || $isHovered
				? colors.tickFillErrorHover
				: colors.tickFillError
		}
		return $isActive || $isHovered ? colors.mono200 : colors.backgroundPrimary
	} else {
		return $isActive || $isHovered
			? colors.tickFillSelectedHover
			: colors.tickFillSelected
	}
}
export const radioStyleOverrides: RadioOverrides = {
	RadioMarkOuter: {
		style({ $theme, $checked, $isActive, $disabled, $error, $isHovered }) {
			let overrides: StyleObject = {
				borderWidth: '1px',
				borderTopStyle: 'solid',
				borderLeftStyle: 'solid',
				borderRightStyle: 'solid',
				borderBottomStyle: 'solid',
				borderTopColor: !$error
					? $theme.colors.black
					: $theme.colors.tickFillErrorSelected,
				borderLeftColor: !$error
					? $theme.colors.black
					: $theme.colors.tickFillErrorSelected,
				borderRightColor: !$error
					? $theme.colors.black
					: $theme.colors.tickFillErrorSelected,
				borderBottomColor: !$error
					? $theme.colors.black
					: $theme.colors.tickFillErrorSelected,
			}

			if (!$disabled) {
				overrides = {
					...overrides,
					backgroundColor: getOuterColor({
						$theme,
						$checked,
						$isActive,
						$isHovered,
						$error,
					}),
				}
			}

			return overrides
		},
	},
	RadioMarkInner: {
		style({ $theme, $checked, $isActive, $disabled, $isHovered, $error }) {
			let overrides: StyleObject = {}

			if (!$disabled) {
				overrides = {
					backgroundColor: getInnerColor({
						$theme,
						$checked,
						$isActive,
						$isHovered,
						$error,
					}),
				}
			}

			return overrides
		},
	},
}
