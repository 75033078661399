import { makeCMSComponent } from '../CMSComponent'

import { default as FAQSComponent } from './FAQS'
import { default as QuestionComponent } from './Question'

export const FAQS = makeCMSComponent(
	{
		tag: 'FAQS',
		label: 'FAQs section',
		fields: {
			title: {
				label: 'Title',
				widget: 'string',
			},
			questions: {
				label: 'FAQ questions',
				widget: 'mdx',
			},
			expandedQuestionKey: {
				label: 'Key of the question to be expanded initially',
				widget: 'string',
			},
		},
	},
	FAQSComponent
)

export const Question = makeCMSComponent(
	{
		tag: 'Question',
		label: 'FAQ question',
		fields: {
			question: {
				label: 'Question Title',
				widget: 'string',
			},
			answer: {
				label: 'Question answer',
				widget: 'string',
			},
		},
	},
	QuestionComponent
)
