export enum EventNames {
	SectionViewed = 'website_section_viewed',
	VideoPlayed = 'website_video_played',
	ButtonClicked = 'website_button_clicked',
	LinkClicked = 'website_link_clicked',
	JoinWaitlistFormViewed = 'join_waitlist_form_viewed',
	WaitlistQuestionsViewed = 'waitlist_questions_viewed',
	WaitlistQuestionsActioned = 'waitlist_questions_actioned',
	JoinWaitlistEmailSubmitted = 'join_waitlist_email_submitted',
	WaitlistQuestionFormSubmitted = 'waitlist_questions_form_submitted',
}
