import { FetchManager } from '@sphere/fetch-manager'

import { GenericFormQuestions } from '../../domain/GenericFormQuestions'
import { GenericFormSubmissionRepo as GenericFormSubmissionRepoInterface } from '../../domain/repositories/GenericFormSubmissionRepo'

type QuestionAndAnswer = { question: string; answer: string | string[] }

interface GenericFormSubmissionDTO {
	formType: string
	formVersion: string
	email: string
	answers: QuestionAndAnswer[]
}

// Maps the domain to data transfer object
function toDTO(
	email: string,
	formType: string,
	formVersion: string,
	data: GenericFormQuestions
): GenericFormSubmissionDTO {
	const questionAnswerPayload = Object.keys(data).map(questionTitle => ({
		question: questionTitle,
		answer: data[questionTitle],
	}))

	return {
		email,
		formType,
		formVersion,
		answers: questionAnswerPayload,
	}
}

export class GenericFormSubmissionRepo
	implements GenericFormSubmissionRepoInterface
{
	static genericFormsSubmissionApiPath = '/api/genericFormSubmissions'

	constructor(
		private _baseApiUrl: string,
		private _fetchManager: FetchManager = new FetchManager()
	) {}

	async submit(
		email: string,
		formType: string,
		formVersion: string,
		formQuestionsData: GenericFormQuestions
	) {
		const dto = toDTO(email, formType, formVersion, formQuestionsData)
		const url = `${this._baseApiUrl}${GenericFormSubmissionRepo.genericFormsSubmissionApiPath}`

		return this._fetchManager.post(url, dto)
	}
}
