import { createContext, Dispatch, Reducer, useReducer } from 'react'

export enum JoinWaitlistActions {
	FormSubmitted = 'FormSubmitted',
	FollowUpSubmitted = 'FollowUpSubmitted',
	Reset = 'Reset',
}

interface JoinWaitlistAction {
	type: JoinWaitlistActions
	payload?: { email: string }
}

interface JoinWaitlistContextValue {
	formSubmitted?: boolean
	followUpSubmitted?: boolean
	waitlistEmail?: string
	dispatch?: Dispatch<JoinWaitlistAction>
}

type JoinWaitlistReducerState =
	| Omit<JoinWaitlistContextValue, 'dispatch'>
	| undefined

const initialContextValue = {
	formSubmitted: false,
	followUpSubmitted: false,
}

export const JoinWaitlistContext =
	createContext<JoinWaitlistContextValue>(initialContextValue)

export const JoinWaitlistProvider: React.FC = ({ children }) => {
	const waitlistReducer: Reducer<JoinWaitlistReducerState, JoinWaitlistAction> =
		(prevState, action) => {
			switch (action.type) {
				case JoinWaitlistActions.FormSubmitted: {
					return {
						...prevState,
						formSubmitted: true,
						waitlistEmail: action?.payload?.email,
					}
				}

				case JoinWaitlistActions.FollowUpSubmitted: {
					return {
						...prevState,
						followUpSubmitted: true,
					}
				}

				case JoinWaitlistActions.Reset: {
					return {
						...initialContextValue,
					}
				}
			}
		}
	const [state, dispatch] = useReducer(waitlistReducer, initialContextValue)

	return (
		<JoinWaitlistContext.Provider value={{ ...state, dispatch }}>
			{children}
		</JoinWaitlistContext.Provider>
	)
}
