import React from 'react'
import { Helmet } from 'react-helmet'

import { primitiveColors } from '../styles/colors'

interface MetaItem {
	name: string
	content: string
}

export interface SEOProps {
	title: string
	description: string
	image: string
	url: string
	keywords?: string[]
	meta?: MetaItem[]
}

type OpenGraphProperty = string | { name: string; value: string }

const openGraphProperties: OpenGraphProperty[] = [
	'title',
	{ name: 'type', value: 'website' },
	'url',
	'image',
	'description',
]

const linkData = [
	{
		rel: 'mask-icon',
		href: '/safari-pinned-tab.svg',
		color: primitiveColors.primary,
	},
	{
		rel: 'icon',
		type: 'image/png',
		href: '/favicon-32x32.png',
		sizes: '32x32',
	},
	{
		rel: 'icon',
		type: 'image/png',
		href: '/favicon-16x16.png',
		sizes: '16x16',
	},
	{
		rel: 'shortcut icon',
		href: '/favicon.ico',
	},
	{
		rel: 'apple-touch-icon',
		href: '/apple-touch-icon.png',
	},
	{
		rel: 'apple-touch-icon',
		href: '/apple-touch-icon-76x76.png',
		sizes: '76x76',
	},
	{
		rel: 'apple-touch-icon',
		href: '/apple-touch-icon-120x120.png',
		sizes: '120x120',
	},
	{
		rel: 'apple-touch-icon',
		href: '/apple-touch-icon-152x152.png',
		sizes: '152x152',
	},
	{
		rel: 'apple-touch-icon',
		href: '/apple-touch-icon-180x180.png',
		sizes: '180x180',
	},
]

const SEO: React.FC<SEOProps> = props => {
	const siteTitle = props.title
	const siteDescription = props.description

	const { keywords = [], meta = [] } = props

	const openGraphMetaTags = openGraphProperties.map(ogProp => {
		let name: string
		let content: string | undefined

		if (typeof ogProp === 'string') {
			name = `og:${ogProp}`
			content =
				ogProp in props
					? props[ogProp as keyof Omit<SEOProps, 'keywords' | 'meta'>]
					: ''
		} else {
			name = `og:${ogProp.name}`
			content = ogProp.value
		}

		return {
			name,
			content,
		}
	})
	return (
		<Helmet
			htmlAttributes={{ lang: 'en' }}
			title={siteTitle}
			meta={openGraphMetaTags
				.concat({
					name: 'description',
					content: siteDescription,
				})
				.concat(
					keywords.length > 0
						? {
								name: 'keywords',
								content: keywords.join(', '),
						  }
						: []
				)
				.concat(meta)}
			link={linkData}
		>
			<script type="text/javascript" src="/config.js"></script>
		</Helmet>
	)
}

export default SEO
