import { FC } from 'react'

const AndroidIcon: FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<rect width="24" height="24" fill="white" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.6347 4.54989L14.8405 4.24729L15.0463 3.94905L15.5048 3.27854C15.5619 3.19582 15.5384 3.08479 15.4534 3.03037C15.3695 2.97486 15.2543 2.99772 15.1995 3.08044L14.4994 4.10035L14.2891 4.4073C13.6237 4.15586 12.8822 4.01545 12.0993 4.01545C11.3176 4.01545 10.575 4.15586 9.90953 4.4073L9.70039 4.10035L9.49349 3.79884L9.0014 3.08044C8.94436 2.99772 8.83029 2.97594 8.74529 3.03037C8.66141 3.08479 8.63792 3.19582 8.69384 3.27854L9.15238 3.94905L9.35817 4.24729L9.56507 4.54989C8.00268 5.2585 6.9458 6.6006 6.9458 8.13645H17.2529C17.2529 6.6006 16.196 5.2585 14.6347 4.54989Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.02521 8.85303H6.9458V16.7358C6.9458 17.3617 7.46809 17.8711 8.11228 17.8711H8.95331C8.92423 17.9658 8.90857 18.0649 8.90857 18.1694V20.4389C8.90857 21.0321 9.40402 21.5132 10.0135 21.5132C10.6231 21.5132 11.1185 21.0321 11.1185 20.4389V18.1694C11.1185 18.0649 11.1017 17.9658 11.0738 17.8711H13.1249C13.0969 17.9658 13.0813 18.0649 13.0813 18.1694V20.4389C13.0813 21.0321 13.5756 21.5132 14.1851 21.5132C14.7958 21.5132 15.2912 21.0321 15.2912 20.4389V18.1694C15.2912 18.0649 15.2744 17.9658 15.2454 17.8711H16.0875C16.7317 17.8711 17.2529 17.3617 17.2529 16.7358V8.85303H7.02521Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.10497 8.85303C4.49433 8.85303 4 9.33414 4 9.92736V14.5262C4 15.1194 4.49433 15.6005 5.10497 15.6005C5.71449 15.6005 6.20882 15.1194 6.20882 14.5262V9.92736C6.20882 9.33414 5.71449 8.85303 5.10497 8.85303Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.0947 8.85303C18.4841 8.85303 17.9897 9.33414 17.9897 9.92736V14.5262C17.9897 15.1194 18.4841 15.6005 19.0947 15.6005C19.7042 15.6005 20.1986 15.1194 20.1986 14.5262V9.92736C20.1986 9.33414 19.7042 8.85303 19.0947 8.85303Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.89038 6.70275C9.58506 6.70275 9.33789 6.46219 9.33789 6.16504C9.33789 5.86788 9.58506 5.62842 9.89038 5.62842C10.1957 5.62842 10.4429 5.86788 10.4429 6.16504C10.4429 6.46219 10.1957 6.70275 9.89038 6.70275Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.3083 6.70275C14.003 6.70275 13.7559 6.46219 13.7559 6.16504C13.7559 5.86788 14.003 5.62842 14.3083 5.62842C14.6137 5.62842 14.8608 5.86788 14.8608 6.16504C14.8608 6.46219 14.6137 6.70275 14.3083 6.70275Z"
			fill="white"
		/>
	</svg>
)

export default AndroidIcon
