declare const window: any
export interface Config {
	tracking?: {
		segmentWriteKey: string
		statsEndpoint: string
	}
	signInUrl?: string
	apiBaseUrl?: string
	unlaunchVariationEnabled?: boolean
	hideAuthLinks?: boolean
}

const defaultConfig = {
	unlaunchVariationEnabled: false,
	hideAuthLinks: false,
}

let config: Config

function getConfig(): Config {
	if (config) {
		return config
	} else if (typeof window !== 'undefined' && window.config) {
		config = { ...window.config }
		delete window.config
		return config
	}
	return defaultConfig
}

export default getConfig
