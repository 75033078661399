import { withStyle } from 'baseui'
import { Block } from 'baseui/block'
import {
	StyledNavigationItem,
	StyledNavigationList,
} from 'baseui/header-navigation'
import { StyledLink } from 'baseui/link'
import { FC } from 'react'
import styled from 'styled-components'

export const MobileNavListItem = withStyle(
	StyledNavigationItem,
	({ $theme }) => ({
		paddingLeft: $theme.sizing.scale200,
		[$theme.mediaQuery.medium]: {
			paddingLeft: $theme.sizing.scale800,
		},
	})
)

export const NavLink = withStyle<
	typeof StyledLink,
	{ $isActive?: boolean; $isHovered?: boolean }
>(StyledLink, ({ $theme, $isActive, $isHovered }) => ({
	display: 'flex',
	textDecoration: 'none',
	color: $theme.colors.colorPrimary,
	':visited': {
		color: $theme.colors.colorPrimary,
	},
	':hover': {
		color: $isActive ? $theme.colors.colorPrimary : $theme.colors.linkHover,
	},
	':hover:visited': {
		color: $isActive ? $theme.colors.colorPrimary : $theme.colors.linkHover,
	},
	borderBottomStyle: 'solid',
	borderBottomColor:
		$isHovered && !$isActive
			? $theme.colors.linkHover
			: $isActive
			? $theme.colors.border
			: 'transparent',
	borderBottomWidth: '2px',
	paddingBottom: $theme.sizing.scale100,
	...$theme.typography.font300,
	[$theme.mediaQuery.medium]: {
		paddingBottom: $theme.sizing.scale300,
	},
	[$theme.mediaQuery.large]: {
		...$theme.typography.font350,
	},
}))

export const MobileLogoWrapper = withStyle(StyledNavigationList, {
	alignItems: 'flex-end',
	marginRight: 'auto',
})

export const MainNavItemsList = withStyle(
	StyledNavigationList,
	({ $theme }) => ({
		flexDirection: 'column',
		alignItems: 'center',
		[$theme.mediaQuery.medium]: {
			flexDirection: 'row',
			alignItems: 'flex-start',
		},
	})
)

export const MobileNav: FC = ({ children }) => (
	<Block flex="1 0 auto" display={['flex', 'flex', 'none']}>
		{children}
	</Block>
)

export const DesktopNav: FC = ({ children }) => (
	<Block flex="1 0 auto" display={['none', 'none', 'flex']}>
		{children}
	</Block>
)

export const NavItemsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 80%;
	max-width: 320px;

	${({ theme }) => theme.mediaQuery.medium} {
		flex-direction: row;
		margin-left: auto;
		width: auto;
		max-width: none;
	}
`
