module.exports = [{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-plugin-netlify-cms@5.8.0_1758ffe93461684c6a6fbb9c84eefb7a/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/builds/yWABz8Mv/0/frontend/web/super-web/packages/gatsby-theme-sphere-cms/lib/index.js"},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-plugin-manifest@3.8.0_gatsby@3.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/builds/yWABz8Mv/0/frontend/web/super-web/sites/main/src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-plugin-mdx@2.8.0_73e8d0148a4c5c71ceb6a02bbce55c80/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/builds/yWABz8Mv/0/frontend/web/super-web/sites/main"},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-plugin-asset-path@3.0.4_gatsby@3.8.0/node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","css","map","png","ico","svg","webm","ogv","mp4","eot","woff","woff2","ttf"]},
    },{
      plugin: require('../../../packages/gatsby-theme-sphere/gatsby-browser.js'),
      options: {"plugins":[],"imagesFolder":"/builds/yWABz8Mv/0/frontend/web/super-web/sites/main/src/images","pagesFolder":"/builds/yWABz8Mv/0/frontend/web/super-web/sites/main/src/pages","cmsEnabled":true,"homePageRoute":"/our-app"},
    }]
