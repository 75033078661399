import styled from 'styled-components'

import LogoImage from '../SVGS/Logo'

const StyledLogoContainer = styled.div`
	width: 120px;

	${({ theme }) => theme.mediaQuery.medium} {
		width: 150px;
	}

	${({ theme }) => theme.mediaQuery.large} {
		width: 210px;
	}
`
const StyledLogo = styled(LogoImage)`
	display: block;
`

export const Logo: React.FC = () => (
	<StyledLogoContainer>
		<StyledLogo />
	</StyledLogoContainer>
)
