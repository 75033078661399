import { DrawerOverrides } from 'baseui/drawer'
import { HeaderNavigationOverrides } from 'baseui/header-navigation'
import { Override } from 'baseui/overrides'

export const headerNavigationOverrides: HeaderNavigationOverrides = {
	Root: {
		style({ $theme }) {
			return {
				backgroundColor: $theme.colors.backgroundPrimary,
				borderBottomWidth: 0,
				paddingLeft: $theme.sizing.scale800,
				paddingRight: $theme.sizing.scale800,
				paddingTop: $theme.sizing.scale400,
				paddingBottom: $theme.sizing.scale400,
				[$theme.mediaQuery.medium]: {
					paddingLeft: $theme.sizing.scale1800,
					paddingRight: $theme.sizing.scale1800,
					paddingTop: $theme.sizing.scale800,
					paddingBottom: $theme.sizing.scale800,
					zIndex: $theme.zIndex.mobileNavDrawer,
				},
			}
		},
	},
}

export const drawerOverrides: Omit<DrawerOverrides, 'Root'> & {
	Root: Override<any>
} = {
	Root: {
		style({ $theme }) {
			return {
				display: 'flex',
				flexDirection: 'column',
				zIndex: $theme.zIndex.mobileNavDrawer,
				[$theme.mediaQuery.medium]: {
					display: 'none',
				},
			}
		},
	},
	DrawerBody: {
		style() {
			return {
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}
		},
	},
}
