import { createTheme } from 'baseui'
import type { Theme as BaseUITheme } from 'baseui/theme'

import { primitiveColors, semanticColors } from '../colors'
import { responsiveTheme } from '../responsive'
import { typography } from '../typography'

import { zIndexes } from './zIndex'

const primitives = {
	primaryFontFamily: 'RebondGrotesque, sans-serif',
	...primitiveColors,
}

const borders = {
	radius100: '8px',
	radius200: '13px',
	radius300: '25px',
	radius400: '40px',
	radius500: '100px',
}

const overrides = {
	colors: semanticColors,
	typography,
	borders,
	sizing: {
		scale1800: '72px',
		scale2000: '80px',
		scale5400: '216px',
	},
	zIndex: zIndexes,
	...responsiveTheme,
}

export type Theme = BaseUITheme & typeof overrides

export type Colors = keyof Theme['colors']

const theme: Theme = createTheme(primitives, overrides)

export { theme }
