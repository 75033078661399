import { FC } from 'react'

import { useClickTracker } from './analytics'
import { JoinWaitlistProvider } from './context/JoinWaitlistProvider'
import { ToggleProvider } from './context/ToggleProvider'
import { TwitterBannerContextProvider } from './context/TwitterBannerContext'
import { SegmentTrackerLoader } from './SegmentTrackerLoader'
import { ThemeProvider } from './styles'

export const SiteProvider: FC = ({ children }) => {
	useClickTracker()

	return (
		<>
			<SegmentTrackerLoader />
			<JoinWaitlistProvider>
				<TwitterBannerContextProvider>
					<ToggleProvider>
						<ThemeProvider>{children}</ThemeProvider>
					</ToggleProvider>
				</TwitterBannerContextProvider>
			</JoinWaitlistProvider>
		</>
	)
}
