import React from 'react'
import styled from 'styled-components'

export interface SectionHeaderImageProps {
	src: string
}

const StyledImage = styled.img`
	height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-right: ${({ theme }) => theme.sizing.scale400};
  margin-left: ${({ theme }) => theme.sizing.scale400};

	${({ theme }) => theme.mediaQuery.medium} {
		height: 72px;
    margin-right: ${({ theme }) => theme.sizing.scale800}
    margin-left: ${({ theme }) => theme.sizing.scale800}
	}
`

const SectionHeaderImage: React.FC<SectionHeaderImageProps> = ({ src }) => (
	<StyledImage src={src} />
)

export default SectionHeaderImage
