import { FC } from 'react'

const TwitterPlusSphere: FC = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 160 64"
		fill="none"
		{...props}
	>
		<path
			d="M134.31 15.4186C134.31 15.4186 134.186 15.4186 134.31 15.4186H134.062C129.471 15.4186 125.749 19.0789 125.749 23.5932V23.7152C125.873 28.2296 129.595 31.8898 134.186 31.8898H149.322C150.191 31.8898 150.935 31.1578 150.935 30.1817C150.935 30.1817 150.935 30.0597 150.935 29.9377C150.067 21.7631 142.995 15.4186 134.31 15.4186ZM134.31 7C148.33 7 159.62 18.1028 159.62 31.8898C159.62 45.6769 148.33 56.7797 134.31 56.7797C120.29 56.7797 109 45.6769 109 31.8898C109 18.1028 120.29 7 134.31 7Z"
			fill="white"
		/>
		<path
			d="M54.3145 20.559C54.3494 21.0628 54.3494 21.5666 54.3494 22.0749C54.3494 37.5659 42.5513 55.4317 20.9781 55.4317V55.4224C14.6053 55.4317 8.36486 53.607 3 50.1667C3.92666 50.2781 4.85796 50.3338 5.79158 50.3361C11.0728 50.3408 16.2031 48.5695 20.358 45.3079C15.3392 45.2127 10.9381 41.9418 9.40067 37.1666C11.1588 37.5055 12.9703 37.4359 14.6959 36.9646C9.22417 35.8596 5.28761 31.0543 5.28761 25.4735C5.28761 25.4225 5.28761 25.3737 5.28761 25.325C6.91797 26.2326 8.74342 26.7364 10.6107 26.7921C5.45715 23.3494 3.8686 16.4965 6.98068 11.1387C12.9354 18.4628 21.7213 22.9153 31.1527 23.3866C30.2075 19.3148 31.4988 15.048 34.5458 12.1856C39.2697 7.74703 46.6992 7.97453 51.1397 12.694C53.7664 12.1763 56.284 11.2129 58.5878 9.84793C57.7123 12.5617 55.8799 14.8669 53.432 16.3317C55.7568 16.0578 58.0281 15.4356 60.1671 14.4862C58.5925 16.8447 56.6091 18.8992 54.3145 20.559Z"
			fill="white"
		/>
		<path
			d="M83.9929 34.636H76.7952V31.3865H83.9929V24.2587H87.3123V31.3865H94.51V34.636H87.3123V42.0433H83.9929V34.636Z"
			fill="white"
		/>
	</svg>
)

export default TwitterPlusSphere
