import { BlockOverrides } from 'baseui/block'
import { ModalOverrides } from 'baseui/modal'
import { Override } from 'baseui/overrides'

export const modalStyleOverrides: ModalOverrides | { Root?: Override<any> } = {
	Dialog: {
		style: ({ $theme }) => ({
			paddingTop: $theme.sizing.scale3200,
			paddingBottom: $theme.sizing.scale3200,
			paddingLeft: $theme.sizing.scale1200,
			paddingRight: $theme.sizing.scale1200,
			marginTop: '0',
			marginLeft: '0',
			marginRight: '0',
			marginBottom: '0',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
		}),
	},
}

export const subtitleParagraphOverrides: BlockOverrides = {
	Block: {
		style: ({ $theme }) => ({
			textAlign: 'center',
			maxWidth: '464px',
			marginLeft: 'auto',
			marginRight: 'auto',
			marginTop: $theme.sizing.scale300,
			marginBottom: $theme.sizing.scale1600,
			...$theme.typography.ParagraphMedium,
			[$theme.mediaQuery.medium]: {
				...$theme.typography.ParagraphLarge,
				marginBottom: $theme.sizing.scale3200,
			},
		}),
	},
}
