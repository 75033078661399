import styled from 'styled-components'

import { H4, ParagraphLarge } from '../Typography'

export interface MissionShapeItemProps {
	title: string
	text: string
	shapeUrl: string
}

interface ShapeProps {
	$shapeImageUrl: string
	flipped: boolean
}

export const ShapeContainer = styled.div`
	width: 300px;
	height: 300px;
	cursor: pointer;

	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;

	max-width: 100%;
	perspective: 1000px;

	&:not(:first-child) {
		margin-left: ${({ theme }) => theme.sizing.scale800};
	}

	${({ theme }) => theme.mediaQuery.medium} {
		width: 524px;
		height: 524px;
	}
`

export const Shape = styled.div<ShapeProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;

	background-repeat: no-repeat;
	background-size: contain;
	background-image: url(${({ $shapeImageUrl }) => $shapeImageUrl});

	will-change: transform;
	transform: ${({ flipped }) =>
		flipped ? 'rotateY(180deg)' : 'rotateY(0deg)'};
	transition: transform 0.5s cubic-bezier(0.87, 0, 0.13, 1); /* ease-in-out-expo */
	transform-style: preserve-3d;
`

export const ShapeHeader = styled(H4)`
	display: flex;
	width: 60%;

	justify-content: center;
	align-items: center;

	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: ${({ theme }) => theme.sizing.scale800};

	backface-visibility: hidden;
	transform: rotateX(0deg);

	${({ theme }) => theme.typography.HeadingXSmall}

	${({ theme }) => theme.mediaQuery.medium} {
		${({ theme }) => theme.typography.HeadingMedium}
	}
`

export const ShapeCTAText = styled(ParagraphLarge)`
	backface-visibility: hidden;
	transform: rotateX(0deg);
	border-bottom: 2px solid ${({ theme }) => theme.colors.border};
	margin: 0;

	${({ theme }) => theme.typography.ParagraphMedium}

	${({ theme }) => theme.mediaQuery.medium} {
		${({ theme }) => theme.typography.ParagraphLarge}
	}
`

export const ShapeText = styled(ParagraphLarge)`
	position: absolute;
	display: flex;
	align-items: center;
	margin: 0;
	left: 50%;
	width: 60%;
	height: 100%;

	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform: rotateY(180deg) translateX(50%) translateZ(1px);

	${({ theme }) => theme.typography.ParagraphMedium}

	${({ theme }) => theme.mediaQuery.medium} {
		${({ theme }) => theme.typography.ParagraphLarge}
	}
`
export const MissionsItemContainer = styled.div`
	margin-left: ${({ theme }) => `-${theme.sizing.scale600}`};
	margin-right: ${({ theme }) => `-${theme.sizing.scale600}`};

	${({ theme }) => theme.mediaQuery.medium} {
		margin-left: ${({ theme }) => `-${theme.sizing.scale1600}`};
		margin-right: ${({ theme }) => `-${theme.sizing.scale1600}`};
	}
`
