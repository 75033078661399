import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { defaults } from 'lodash'
import { FC, memo } from 'react'

import {
	Layout as LayoutWrapper,
	Page,
	Header,
	TwitterBanner,
	Footer,
	SEO,
} from '@sphere/site-components'

type PageContext = PageProps<
	object,
	{ frontmatter?: { title: string; description: string; image: string } }
>['pageContext']

interface LayoutProps {
	location: PageProps['location']
	pageContext?: PageContext
}

interface SEOProps {
	title: string
	description: string
	image: string
}

const ZWERL_PAGE_PATH_IDENTIFIER = 'zwerl'

function getSEOProps(
	pageContext: PageContext,
	siteMetadata?: Pick<
		GatsbyTypes.SiteSiteMetadata,
		'title' | 'description' | 'image'
	>
): SEOProps {
	return defaults(pageContext.frontmatter, {
		title: siteMetadata?.title,
		description: siteMetadata?.description,
		image: siteMetadata?.image,
	})
}
const Layout: FC<LayoutProps> = ({ children, location, pageContext }) => {
	const { pathname, origin } = location

	const data = useStaticQuery<GatsbyTypes.LayoutLinksQuery>(graphql`
		query LayoutLinks {
			site {
				siteMetadata {
					title
					description
					image
				}
			}
		}
	`)

	const { title, description, image } = getSEOProps(
		pageContext!,
		data.site?.siteMetadata
	)

	if (pathname.includes(ZWERL_PAGE_PATH_IDENTIFIER)) {
		return (
			<LayoutWrapper>
				<SEO
					title={title}
					description={description}
					image={image}
					url={origin + pathname.replace(/\/$/, '')}
				/>
				<Page>
					<main>{children}</main>
				</Page>
			</LayoutWrapper>
		)
	}

	return (
		<LayoutWrapper>
			<SEO
				title={title}
				description={description}
				image={image}
				url={origin + pathname.replace(/\/$/, '')}
			/>
			<TwitterBanner readWhyCtaUrl="https://medium.com/spheremessenger/sphere-has-joined-twitter-e7127cfe0467" />
			<Header menuLinks={[]} currentPathname={pathname} />
			<Page>
				<main>{children}</main>
				<Footer />
			</Page>
		</LayoutWrapper>
	)
}

export default memo(Layout)
