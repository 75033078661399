import { makeCMSComponent } from '../CMSComponent'

import { default as ZwerlComponent } from './Zwerl'

export const Zwerl = makeCMSComponent(
	{
		tag: 'Zwerl',
		label: 'Zwerl page',
		fields: {
			title: {
				label: 'Title',
				widget: 'string',
			},
			description: {
				label: 'Description',
				widget: 'text',
			},
			bottomText: {
				label: 'Text at the bottom in bold',
				widget: 'string',
			},
		},
	},
	ZwerlComponent
)
