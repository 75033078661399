import { css } from 'styled-components'

const typography = {
	HeadingXSmall: {
		fontWeight: '600',
		fontSize: '1.5rem',
		lineHeight: '2rem',
	},
	HeadingSmall: {
		fontWeight: '600',
		fontSize: '2rem',
		lineHeight: '2.5rem',
	},
	HeadingMedium: {
		fontWeight: '600',
		fontSize: '2.5rem',
		lineHeight: '3rem',
	},
	HeadingLarge: {
		fontWeight: '600',
		fontSize: '3rem',
		lineHeight: '3.5rem',
	},
	HeadingXLarge: {
		fontWeight: '600',
		fontSize: '4rem',
		lineHeight: '4.5rem',
	},
	HeadingXXLarge: {
		fontWeight: '600',
		fontSize: '4.5rem',
		lineHeight: '5rem',
	},
	ParagraphSmall: {
		fontSize: '1rem',
		lineHeight: '1.25rem',
	},
	ParagraphMedium: {
		fontSize: '1.25rem',
		lineHeight: '1.5rem',
	},
	ParagraphLarge: {
		fontSize: '1.5rem',
		lineHeight: '2rem',
	},
	font350: {
		fontSize: '1.25rem',
		lineHeight: '1.25rem',
	},
	font450: {
		fontSize: '1.5rem',
		lineHeight: '2rem',
	},
}
export default typography

export const fontFace = css`
	@font-face {
		font-family: 'RebondGrotesque';
		src: local('Rebond Grotesque'), local('RebondGrotesque'),
			url(/cloudinary/sphere/raw/upload/v1622545558/fonts/RebondGrotesque-Regular.woff2)
				format('woff2'),
			url(/cloudinary/sphere/raw/upload/v1622545558/fonts/RebondGrotesque-Regular.woff)
				format('woff'),
			url(/cloudinary/sphere/raw/upload/v1622545558/fonts/RebondGrotesque-Regular.eot)
				format('eot');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'RebondGrotesque';
		src: local('RebondGrotesque'), local('RebondGrotesque'),
			url(/cloudinary/sphere/raw/upload/v1622545558/fonts/RebondGrotesque-Semibold.woff2)
				format('woff2'),
			url(/cloudinary/sphere/raw/upload/v1622545558/fonts/RebondGrotesque-Semibold.woff)
				format('woff'),
			url(/cloudinary/sphere/raw/upload/v1622545558/fonts/RebondGrotesque-Semibold.eot)
				format('eot');
		font-weight: 600;
		font-style: normal;
	}

	@font-face {
		font-family: 'Gotham';
		src: local('Gotham'), local('Gotham'),
			url(/cloudinary/sphere/raw/upload/v1626878481/fonts/GothamBook.woff)
				format('woff'),
			url(/cloudinary/sphere/raw/upload/v1626878480/fonts/GothamBook.ttf)
				format('ttf');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'GothamBold';
		src: local('GothamBold'), local('GothamBold'),
			url(/cloudinary/sphere/raw/upload/v1626878480/fonts/Gotham-Bold.woff)
				format('woff'),
			url(/cloudinary/sphere/raw/upload/v1626878480/fonts/GothamBold.ttf)
				format('ttf');
		font-weight: 700;
		font-style: normal;
	}
`

export const primaryFontFamily = 'RebondGrotesque, sans-serif'
