import { BaseProvider } from 'baseui'
import { Theme } from 'baseui/theme'
import { normalize } from 'polished'
import {
	createGlobalStyle,
	ThemeProvider as StyledComponentThemeProvider,
} from 'styled-components'
import {
	Client as StyletronClient,
	Server as StyletronServer,
	hydrateType,
} from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'

import { fontFace, primaryFontFamily } from '../typography'

import { theme as defaultTheme } from './default'

export let serverEngine: StyletronServer | null = null
export let clientEngine: StyletronClient | null = null

const savedStyles = global['document']?.getElementsByClassName?.(
	'_styletron_hydrate_'
)

if (savedStyles) {
	clientEngine = new StyletronClient({
		hydrate: savedStyles as hydrateType,
	})
} else {
	serverEngine = new StyletronServer()
}

const GlobalStyle = createGlobalStyle`
  ${normalize()}
  ${fontFace}
   a {
     text-decoration: none;
     &:active {
       color: black;
     }
   }
   html,
   body {
     margin: 0;
     padding: 0;
     -webkit-overflow-scrolling: touch;
     font-family: ${primaryFontFamily};
     text-rendering: optimizeLegibility;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     -moz-font-feature-settings: 'liga' on;
     font-feature-settings: 'liga' on;
   }
 `

interface ThemeProviderProps {
	theme?: Theme
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
	children,
	theme = defaultTheme,
}) => (
	<StyletronProvider value={savedStyles ? clientEngine! : serverEngine!}>
		<BaseProvider theme={theme}>
			<StyledComponentThemeProvider theme={theme}>
				<GlobalStyle />
				{children}
			</StyledComponentThemeProvider>
		</BaseProvider>
	</StyletronProvider>
)
