import styled from 'styled-components'

export const SectionContainer = styled.section`
	text-align: center;
	padding-top: ${({ theme }) => theme.sizing.scale3200};
	padding-bottom: ${({ theme }) => theme.sizing.scale3200};
	padding-left: ${({ theme }) => theme.sizing.scale800};
	padding-right: ${({ theme }) => theme.sizing.scale800};

	${({ theme }) => theme.mediaQuery.medium} {
		padding-top: ${({ theme }) => theme.sizing.scale5400};
		padding-bottom: ${({ theme }) => theme.sizing.scale5400};
		padding-left: ${({ theme }) => theme.sizing.scale1800};
		padding-right: ${({ theme }) => theme.sizing.scale1800};
	}
`

export const EmptySectionContainer = styled.section`
	padding; 0;
	margin: 0;
`
