import { makeCMSComponent } from '../CMSComponent'

import { default as JoinWaitlistBannerComponent } from './JoinWaitlistBanner'

export const JoinWaitlistBanner = makeCMSComponent(
	{
		tag: 'JoinWaitlistBanner',
		label: 'JoinWaitlistBanner - Section which shows text and CTA to Waitlist',
		fields: {
			ctaText: {
				label: 'Section CTA button text',
				widget: 'string',
			},
		},
	},
	JoinWaitlistBannerComponent
)
