import { BlockProps } from 'baseui/block'
import {
	ParagraphMedium,
	ParagraphLarge,
	ParagraphSmall,
} from 'baseui/typography'
import { FC } from 'react'

export interface ParagraphSizes {
	small: 'small'
	medium: 'medium'
	large: 'large'
}

type SizeKeys = keyof ParagraphSizes

type ParagraphSizeToComponentMap = {
	[size in SizeKeys]: FC<BlockProps>
}
const paragraphSizeToComponentMap: ParagraphSizeToComponentMap = {
	small: ParagraphSmall,
	medium: ParagraphMedium,
	large: ParagraphLarge,
}

export type ParagraphProps = {
	size?: string // TODO: Change to ParagraphSizes
	text: string
	$textAlign?: string
}

export const ParagraphComponent: FC<ParagraphProps> = ({
	size = 'medium',
	$textAlign = 'left',
	children,
}) => {
	const Component = paragraphSizeToComponentMap[size as SizeKeys]

	return (
		<Component
			as="p"
			overrides={{
				Block: {
					style: ({ $theme }) => {
						const style = {
							textAlign: $textAlign,
						}

						if (size === 'large') {
							return {
								...style,
								...$theme.typography.ParagraphMedium,
								[$theme.mediaQuery.medium]: {
									...$theme.typography.ParagraphLarge,
								},
							}
						}

						if (size === 'medium') {
							return {
								...style,
								...$theme.typography.ParagraphSmall,
								[$theme.mediaQuery.medium]: {
									...$theme.typography.ParagraphMedium,
								},
							}
						}
						// For 'small' we just rely on default
						return style
					},
				},
			}}
		>
			{children}
		</Component>
	)
}

export default ParagraphComponent
