import { Formik, Form as FormikForm } from 'formik'
import { useCallback, useEffect, useState } from 'react'

import { useToggle } from '../context/ToggleProvider'
import useJoinWaitlist from '../hooks/useJoinWaitlist'
import useWaitlistFollowUp from '../hooks/useWaitlistFollowUp'

import { FormError, FormSuccess } from './styled-components'

import { SubmitButton } from './index'

const submitHandlerHooksByFormName = {
	'join-waitlist': useJoinWaitlist,
	'join-waitlist-follow-up': useWaitlistFollowUp,
}

type HooksByFormName = typeof submitHandlerHooksByFormName
type FormName = keyof HooksByFormName

function isFormNameRegistered(name: string): name is FormName {
	return name in submitHandlerHooksByFormName
}

function getFormSubmitHookByName(
	formName: string
): HooksByFormName[keyof HooksByFormName] {
	if (!isFormNameRegistered(formName)) {
		throw new Error(
			`No form submit hook is defined for form with name ${formName}`
		)
	}
	return submitHandlerHooksByFormName[formName]
}

export enum FormSuccessAction {
	Toggle = 'Toggle',
	SuccessMessage = 'SuccessMessage',
	None = 'None',
}
export interface FormProps {
	name: string
	errorMessage: string
	successActionType: string // TODO: Figure out how we can use enum is CMS components
	successMessage: string
	fields: string
	submitText: string
	version?: string
	onSubmit?(values: Record<string, unknown>): void
	onSubmitted?(): void
	onChange?(): void
	targetToggleName?: string
}

const Form: React.FC<FormProps> = ({
	successMessage = '',
	successActionType = FormSuccessAction.SuccessMessage,
	targetToggleName,
	version = '1',
	...props
}) => {
	const useFormSubmit = getFormSubmitHookByName(props.name)

	const { submit, state, error } = useFormSubmit(version)

	const [currentTargetToggleValue, toggleTarget] = useToggle({
		name: targetToggleName!,
	})

	const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
	const { onSubmit, onSubmitted, onChange = () => {} } = props

	const handleSubmit = useCallback(
		(values: any) => {
			submit(values)
			onSubmit?.(values)
		},
		[onSubmit, submit]
	)

	useEffect(() => {
		if (state === 'submitted') {
			switch (successActionType) {
				case FormSuccessAction.SuccessMessage: {
					setShowSuccessMessage(true)
					break
				}

				case FormSuccessAction.Toggle: {
					if (targetToggleName && !currentTargetToggleValue) {
						toggleTarget()
						onSubmitted?.()
					}
					break
				}

				case FormSuccessAction.None: {
					onSubmitted?.()
					break
				}
			}

			onSubmitted?.()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		state,
		successActionType,
		targetToggleName,
		toggleTarget,
		onSubmitted,
		setShowSuccessMessage,
	])

	return (
		<Formik
			onSubmit={handleSubmit}
			validateOnChange={true}
			validateOnBlur={true}
			initialValues={{}}
		>
			<FormikForm name={props.name} onChange={onChange}>
				<>
					{props.children}
					{error && <FormError>{props.errorMessage}</FormError>}
					{showSuccessMessage && successMessage && (
						<FormSuccess>{successMessage}</FormSuccess>
					)}
					<SubmitButton
						isLoading={
							(state === 'submitting' && !error) || state === 'submitted'
						}
						text={props.submitText}
						$fullWidth={false}
					></SubmitButton>
				</>
			</FormikForm>
		</Formik>
	)
}

export default Form
