import { FC, useRef, useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'

export const StyledVideo = styled.video`
	object-fit: cover;
	height: auto;
	max-width: 100%;
	overflow: hidden;

	&:fullscreen {
		object-fit: scale-down;
	}

	&:-webkit-full-screen {
		object-fit: scale-down;
	}
`

const VIDEO_LOAD_FALLBACK_TIMEOUT = 500 // 0.5 seconds

type VideoTypes = Record<string, string>

const VideoType: VideoTypes = {
	mp4: 'video/mp4',
	web: 'video/webm',
	ogv: 'video/ogg',
	mov: 'video/quicktime',
}

const Video: FC<{ src: string; onLoad?(): void }> = ({ src, onLoad }) => {
	const ref = useRef<HTMLVideoElement>(null)
	const extension = src.split('.').pop()

	const [isLoaded, setLoaded] = useState(false)

	// Fallback in case the events are not fired
	useEffect(() => {
		if (ref.current?.readyState !== 4 && !isLoaded) {
			setLoaded(true)
		} else {
			if (!isLoaded) {
				setTimeout(() => setLoaded(true), VIDEO_LOAD_FALLBACK_TIMEOUT)
			}
		}
	}, [ref, isLoaded])

	useEffect(() => {
		if (isLoaded) {
			onLoad!()
		}
	}, [isLoaded, onLoad])

	const handleVideoLoaded = useCallback(() => {
		if (ref.current && ref.current.readyState > 3 && !isLoaded) {
			setLoaded(true)
		}
	}, [isLoaded, setLoaded, ref])

	if (!extension) {
		return null
	}

	const videoTypeFromExtension = VideoType[extension]

	return (
		<StyledVideo
			ref={ref}
			controls={false}
			preload="auto"
			autoPlay={true}
			loop={true}
			muted={true}
			playsInline={true}
			onLoadedData={handleVideoLoaded}
			onCanPlayThrough={handleVideoLoaded} // This is because loadeddata does not always fire
		>
			<source src={src} type={videoTypeFromExtension} />
		</StyledVideo>
	)
}

export default Video
