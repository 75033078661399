const baseTokens = {
	sky: '#5EBDFF',
	charcoal: '#202020',
	snow: '#FFFFFF',
	black: '#000000',
	royal: '#A282FF',
	sun: '#FFDF47',
	grass: '#00C063',
	fire: '#FF874C',
	smoke: '#C4C4C4',
	whiteSmoke: '#EFEFEF',
	sage: '#DBE4EA',
}

export default baseTokens
