import { withStyle } from 'baseui'
import { ParagraphLarge } from 'baseui/typography'
import React, { useContext, useEffect } from 'react'

import { EventNames, useTracking } from '../analytics'
import { JoinWaitlistContext } from '../context/JoinWaitlistProvider'
import { useToggle } from '../context/ToggleProvider'
import {
	Modal,
	ModalHeader as DefaultHeader,
	ModalBody as DefaultModalBody,
} from '../Modal'

import { modalStyleOverrides } from './style-override'

interface JoinWaitlistModalProps {
	name: string
	title: string
	subtitle: string
}

const ModalHeader = withStyle(DefaultHeader, ({ $theme }) => ({
	...$theme.typography.HeadingMedium,
	textAlign: 'center',
	[$theme.mediaQuery.medium]: {
		...$theme.typography.HeadingLarge,
	},
}))

const ModalBody = withStyle(DefaultModalBody, ({ $theme }) => ({
	marginTop: $theme.sizing.scale600,
	marginBottom: $theme.sizing.scale1200,
	marginLeft: $theme.sizing.scale1200,
	marginRight: $theme.sizing.scale1200,
}))

const JoinWaitlistFormModal: React.FC<JoinWaitlistModalProps> = ({
	children,
	name,
	title,
	subtitle,
}) => {
	const [isModalOpen, toggle] = useToggle({
		name,
		initialValue: false,
	})
	const { formSubmitted } = useContext(JoinWaitlistContext)
	const { trackEvent } = useTracking({
		once: [EventNames.JoinWaitlistFormViewed],
	})

	useEffect(() => {
		if (formSubmitted && isModalOpen) {
			toggle()
		}
	}, [formSubmitted, toggle, isModalOpen])

	useEffect(() => {
		// Tracking when modal is opened first time
		if (isModalOpen) {
			trackEvent(EventNames.JoinWaitlistFormViewed)
		}
	}, [isModalOpen, trackEvent])

	return (
		<Modal
			onClose={toggle}
			isOpen={isModalOpen}
			overrides={modalStyleOverrides}
		>
			<ModalHeader>{title}</ModalHeader>
			<ModalBody>
				<>
					<ParagraphLarge
						overrides={{
							Block: {
								style: ({ $theme }) => ({
									textAlign: 'center',
									marginTop: $theme.sizing.scale300,
									...$theme.typography.ParagraphMedium,
									[$theme.mediaQuery.medium]: {
										...$theme.typography.ParagraphLarge,
									},
								}),
							},
						}}
					>
						{subtitle}
					</ParagraphLarge>
					{children}
				</>
			</ModalBody>
		</Modal>
	)
}

export default JoinWaitlistFormModal
