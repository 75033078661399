import { MDXProvider } from '@mdx-js/react'
import { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from 'gatsby'
import { ReactElement } from 'react'

import { Components, SiteProvider } from '@sphere/site-components'

import Layout from './Layout'

export function wrapRootElement({ element }: { element: ReactElement }) {
	return (
		<MDXProvider components={Components}>
			<SiteProvider>{element}</SiteProvider>
		</MDXProvider>
	)
}

export function wrapPageElement({
	element,
	props,
}: WrapPageElementNodeArgs | WrapPageElementBrowserArgs) {
	return <Layout {...props}>{element}</Layout>
}
