import { FC } from 'react'

const DownChevron: FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path d="M3 7L12.5 16L22 7" stroke="white" strokeWidth="2" />
	</svg>
)

export default DownChevron
