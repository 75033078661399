import { BlockOverrides } from 'baseui/block'
import { FC } from 'react'
import styled, { css } from 'styled-components'

import { SIZE, SHAPE, KIND, Button } from '../../Button'
import TwitterPlusSphere from '../../SVGS/TwitterPlusSphere'
import { H3 } from '../../Typography'
import {
	TwitterStickyBannerContainer,
	TwitterSphereIconContainer,
	StickyBannerButtonContainer,
} from '../styled-components'

export interface TwitterStickyBannerProps {
	ctaText: string
	ctaUrl: string
}

const BannerHeader = styled(H3)`
	flex-grow: 0;
	text-align: center;
	margin-right: 0.5rem;
`

const TwitterPlusSphereWithDimensions = styled(TwitterPlusSphere)(
	({ theme }) => css`
		width: 65px;
		display: block;
		${theme.mediaQuery.medium} {
			width: 132px;
		}
	`
)

const bannerHeaderOverrides: BlockOverrides = {
	Block: {
		style({ $theme }) {
			return {
				...$theme.typography.HeadingXSmall,
				fontSize: '1.2rem',
				lineHeight: '1.4rem',
				marginTop: 0,
				marginBottom: 0,
				color: $theme.colors.colorSecondary,

				[$theme.mediaQuery.medium]: {
					...$theme.typography.HeadingLarge,
				},
			}
		},
	},
}
const TwitterStickyBanner: FC<TwitterStickyBannerProps> = ({
	ctaText,
	ctaUrl,
}) => (
	<TwitterStickyBannerContainer>
		<TwitterSphereIconContainer>
			<TwitterPlusSphereWithDimensions />
		</TwitterSphereIconContainer>

		<BannerHeader overrides={bannerHeaderOverrides}>
			Sphere has joined Twitter!
		</BannerHeader>
		<StickyBannerButtonContainer>
			<Button
				$as="a"
				href={ctaUrl}
				kind={KIND.tertiary}
				data-tracking
				size={SIZE.compact}
				shape={SHAPE.pill}
			>
				{ctaText}
			</Button>
		</StickyBannerButtonContainer>
	</TwitterStickyBannerContainer>
)

export default TwitterStickyBanner
