import {
	Button as BaseUIButton,
	ButtonProps as BaseUIButtonProps,
} from 'baseui/button'

import { overrideStyles } from './style-override'

export interface ButtonProps extends BaseUIButtonProps {}

const Button: React.FC<ButtonProps> = ({ children, ...props }) => (
	<BaseUIButton
		{...props}
		overrides={{
			BaseButton: {
				style: overrideStyles,
			},
		}}
	>
		{children}
	</BaseUIButton>
)

export { SHAPE, SIZE, KIND } from 'baseui/button'
export default Button
