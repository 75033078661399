import { makeCMSComponent } from '../CMSComponent'

import { default as MissionsComponent } from './Missions'
import { default as MissionShapeItemComponent } from './MissionShapeItem'

export const Missions = makeCMSComponent(
	{
		tag: 'Missions',
		label: 'Missions section',
		fields: {
			title: {
				label: 'Title',
				widget: 'string',
			},
			items: {
				label: 'Mission items',
				widget: 'mdx',
			},
		},
	},
	MissionsComponent
)

export const MissionShapeItem = makeCMSComponent(
	{
		tag: 'MissionShapeItem',
		label: 'Missions section shape item',
		fields: {
			header: {
				label: 'Header',
				widget: 'string',
			},
			ctaText: {
				label: 'Text underneath header to drive CTA',
				widget: 'string',
			},
			text: {
				label: 'Text',
				widget: 'string',
			},
			shapeUrl: {
				label: 'Url to the shape',
				widget: 'image',
			},
		},
	},
	MissionShapeItemComponent
)
