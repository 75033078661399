import styled from 'styled-components'

export const HorizontalScrollContainer = styled.div`
	display: flex;
	overflow-x: scroll;

	max-width: 100%;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	-webkit-overflow-scrolling: touch;
	&::-webkit-scrollbar {
		display: none;
	}
`
