import { FormikContextType, useFormikContext } from 'formik'
import { useCallback } from 'react'
import styled from 'styled-components'

import { Button, ButtonProps, SHAPE, SIZE } from '../Button'

export interface SubmitButtonProps extends ButtonProps {
	text: string
	$fullWidth: boolean
}

const SubmitButtonContainer = styled.div`
	text-align: center;
`
const SubmitButtonStyled = styled(Button)`
	width: 100%;
	margin-top: ${({ theme }) => theme.sizing.scale500};
	max-width: ${({ $fullWidth }: { $fullWidth: boolean }) =>
		$fullWidth ? '100%' : '397px'};
`

const createTouched = (context: FormikContextType<unknown>) =>
	Object.keys(context.errors).reduce(
		(touched, fieldName) => ({
			...touched,
			[fieldName]: true,
		}),
		context.touched
	)

const SubmitButton: React.FC<SubmitButtonProps> = ({
	text = 'submit',
	$fullWidth = false,
	children,
	...props
}) => {
	const context = useFormikContext()

	const handleSubmitClick = useCallback(
		event => {
			// We only show errors when the field is touched
			// This is to avoid showing errors before user
			// Fills the input. But we need to show errors
			// during submit.
			context.setTouched(createTouched(context), true)

			// Avoid submitting the form when we have errors
			// TODO: Find a better way
			if (context.errors && Object.keys(context.errors).length) {
				event.preventDefault()
			}
		},
		[context]
	)

	return (
		<SubmitButtonContainer>
			<SubmitButtonStyled
				type="submit"
				size={SIZE.large}
				shape={SHAPE.pill}
				$fullWidth={$fullWidth}
				onClick={handleSubmitClick}
				{...props}
			>
				{children ? children : text}
			</SubmitButtonStyled>
		</SubmitButtonContainer>
	)
}

export default SubmitButton
