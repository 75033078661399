import styled, { css } from 'styled-components'

import { LinkComponent as Link } from '../Link'

export const FooterContainer = styled.footer(
	({ theme }) => css`
		display: flex;
		align-items: center;
		jusitify-content: center;

		position: relative;
		background-color: ${theme.colors.snow};
		padding-top: ${theme.sizing.scale1600};
		padding-bottom: ${theme.sizing.scale1600};
		padding-left: ${theme.sizing.scale800};
		padding-right: ${theme.sizing.scale800};

		${theme.mediaQuery.medium} {
			padding-top: ${theme.sizing.scale3200};
			padding-bottom: ${theme.sizing.scale2400};
		}
	`
)

export const FooterContent = styled.div`
	margin: auto;
	max-width: 600px;
`

export const FooterSocialLinks = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: ${theme.sizing.scale3200};
		margin-bottom: ${theme.sizing.scale2400};

		${theme.mediaQuery.medium} {
			margin-top: ${theme.sizing.scale1000};
		}
	`
)

export const FooterNormalLinks = styled.div(
	({ theme }) => css`
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0;
		padding: 0;
	`
)

export const SocialLink = styled(Link)(
	({ theme }) => css`
		color: ${theme.colors.colorPrimary};
		text-decoration: none;
		${{ ...theme.typography.HeadingMedium }}

		&:visited {
			color: ${theme.colors.colorPrimary};
		}

		&:hover {
			color: ${theme.colors.linkHover};
		}

		&:active {
			text-decoration: none;
		}

		${theme.mediaQuery.medium} {
			${{ ...theme.typography.HeadingXLarge }}
		}

		&:not(:first-child) {
			margin-top: ${theme.sizing.scale800};
		}
	`
)

export const NormalLink = styled(Link)(
	({ theme }) => css`
		${{ ...theme.typography.ParagraphMedium }}
		color: ${theme.colors.colorPrimary};
		margin-bottom: ${theme.sizing.scale800};
		text-decoration: underline;

		&:visited {
			color: ${theme.colors.colorPrimary};
		}

		&:hover {
			color: ${theme.colors.linkHover};
		}

		&:not(:first-child) {
			margin-left: ${theme.sizing.scale800};
		}

		${theme.mediaQuery.medium} {
			${{ ...theme.typography.ParagraphLarge }}
		}
	`
)
