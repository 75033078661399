import { PanelOverrides } from 'baseui/accordion'
import { BlockOverrides } from 'baseui/block'

export const questionStyleOverrides: PanelOverrides<any> = {
	PanelContainer: {
		style({ $theme }) {
			return {
				backgroundColor: 'transparent',
				paddingTop: $theme.sizing.scale900,
				paddingBottom: $theme.sizing.scale900,
				paddingRight: $theme.sizing.scale800,
				paddingLeft: $theme.sizing.scale800,
				borderBottomColor: $theme.colors.black,
				position: 'relative',
				boxSizing: 'border-box',
				[$theme.mediaQuery.medium]: {
					paddingRight: $theme.sizing.scale1800,
					paddingLeft: $theme.sizing.scale1800,
				},
			}
		},
	},
	Content: {
		style({ $theme }) {
			return {
				backgroundColor: 'transparent',
				paddingRight: '0',
				paddingLeft: '0',
				paddingBottom: '0',
				...$theme.typography.ParagraphMedium,
				[$theme.mediaQuery.medium]: {
					paddingTop: $theme.sizing.scale800,
					paddingBottom: $theme.sizing.scale800,
					paddingRight: $theme.sizing.scale2400,
					...$theme.typography.ParagraphLarge,
				},
			}
		},
	},
	ContentAnimationContainer: {
		style() {
			return {
				flexGrow: 1,
				flexBasis: '50%',
			}
		},
	},
	Header: {
		style({ $theme }) {
			return {
				backgroundColor: 'transparent',
				':hover': {
					color: $theme.colors.black,
				},
				...$theme.typography.HeadingXSmall,
				paddingLeft: '0',
				paddingTop: '0',
				paddingBottom: '0',
				paddingRight: '0',
				[$theme.mediaQuery.medium]: {
					...$theme.typography.HeadingSmall,
				},
			}
		},
	},
	ToggleIcon: {
		style({ $theme }) {
			// TODO: This is crap, change to a better style
			return {
				width: '30px',
				height: '30px',
				borderWidth: '1px',
				borderStyle: 'solid',
				borderRadius: '50%',
				[$theme.mediaQuery.medium]: {
					width: '46px',
					height: '46px',
				},
			}
		},
	},
}

export const sectionHeaderOverrides: BlockOverrides = {
	Block: {
		style: ({ $theme }) => ({
			...$theme.typography.HeadingMedium,
			marginTop: '0',
			marginBottom: $theme.sizing.scale800,
			paddingRight: $theme.sizing.scale800,
			paddingLeft: $theme.sizing.scale800,
			[$theme.mediaQuery.medium]: {
				marginBottom: $theme.sizing.scale1600,
				paddingRight: $theme.sizing.scale1800,
				paddingLeft: $theme.sizing.scale1800,
				...$theme.typography.HeadingXLarge,
			},
			[$theme.mediaQuery.large]: {
				...$theme.typography.HeadingXXLarge,
			},
		}),
	},
}
