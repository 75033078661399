import { Block } from 'baseui/block'
import { ALIGN, StyledNavigationList } from 'baseui/header-navigation'
import { StyledLink } from 'baseui/link'
import React, { useMemo } from 'react'

import { useTracking, EventNames } from '../analytics'
import { KIND, SHAPE, Button } from '../Button'
import getConfig from '../infra/config/config'
import { ToggleButton } from '../ToggleButton'

import { Logo } from './Logo'
import {
	MainNavItemsList,
	NavItemsWrapper,
	DesktopNav,
} from './styled-components'

interface DesktopNavbarProps {
	signInUrl?: string
}

const DesktopNavbar: React.FC<DesktopNavbarProps> = ({
	signInUrl,
	children,
}) => {
	const { trackEvent } = useTracking()
	const shouldShowAuthLinks = !getConfig().hideAuthLinks

	const navItems = useMemo(
		() => (
			<>
				<MainNavItemsList>{children}</MainNavItemsList>
				{shouldShowAuthLinks && (
					<Block marginLeft={[0, 'scale800']}>
						<Button
							onClick={() => {
								trackEvent(EventNames.LinkClicked, {
									link_url: signInUrl!,
								})
								window.location.assign(signInUrl!)
							}}
							kind={KIND.tertiary}
							shape={SHAPE.pill}
						>
							Sign in
						</Button>
					</Block>
				)}
				{shouldShowAuthLinks && (
					<Block marginLeft={[0, 'scale800']}>
						<ToggleButton
							data-tracking
							targetName="join-waitlist"
							kind={KIND.secondary}
							shape={SHAPE.pill}
						>
							Join waitlist
						</ToggleButton>
					</Block>
				)}
			</>
		),
		[children, signInUrl, trackEvent, shouldShowAuthLinks]
	)

	return (
		<DesktopNav>
			<StyledNavigationList $align={ALIGN.left}>
				<StyledLink data-tracking href="/" title="Sphere">
					<Logo />
				</StyledLink>
			</StyledNavigationList>
			<NavItemsWrapper>{navItems}</NavItemsWrapper>
		</DesktopNav>
	)
}

export default DesktopNavbar
