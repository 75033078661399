import { ParagraphLarge } from 'baseui/typography'
import { FC, ReactElement, Children, useMemo } from 'react'

import { Button, SHAPE, SIZE } from '../Button'
import { FullPageModal, FullPageModalBody } from '../Modal'

import {
	subtitleParagraphOverrides,
	modalStyleOverrides,
} from './style-override'

export interface SuccessModalProps {
	subtitle: string
	buttonText: string
	isOpen: boolean
	onClose: () => void
}

const SuccessModal: FC<SuccessModalProps> = ({
	buttonText,
	subtitle,
	isOpen,
	onClose,
	children,
}) => {
	const modalHeader = useMemo(() => {
		const childrenArray = Children.toArray(children)

		// TODO: Find a better way like using child.type
		// as the component should not know about CMS/MDX related props
		const header = childrenArray.find(
			child => (child as ReactElement).props.mdxType === 'SuccessModalHeader'
		)

		if (!header) {
			throw new Error('SuccessModalHeader should be provided as children')
		}

		return header
	}, [children])

	return (
		<FullPageModal
			onClose={onClose}
			isOpen={isOpen}
			showCloseButton={false}
			overrides={modalStyleOverrides}
		>
			{modalHeader}
			<FullPageModalBody>
				<>
					<ParagraphLarge overrides={subtitleParagraphOverrides}>
						{subtitle}
					</ParagraphLarge>
					<Button size={SIZE.large} shape={SHAPE.pill} onClick={onClose}>
						{buttonText}
					</Button>
				</>
			</FullPageModalBody>
		</FullPageModal>
	)
}
export default SuccessModal
