import { makeCMSComponent } from '../CMSComponent'

import ParagraphComponent from './Paragraph'

export * from 'baseui/typography'

export const Paragraph = makeCMSComponent(
	{
		tag: 'Paragraph',
		label: 'Paragraph',
		fields: {
			text: {
				label: 'Text',
				widget: 'mdx',
			},
			size: {
				label: 'Size',
				widget: 'string',
			},
		},
	},
	ParagraphComponent
)
