import { makeCMSComponent } from '../CMSComponent'

import { default as FeatureComponent } from './Feature'

export const Feature = makeCMSComponent(
	{
		tag: 'Feature',
		label: 'Our app feature',
		fields: {
			name: {
				label: 'Name of the feature',
				widget: 'string',
			},
			header: {
				label: 'Header',
				widget: 'string',
			},
			text: {
				label: 'Text',
				widget: 'string',
			},
			lottieUrl: {
				label: 'Url to lottie animation',
				widget: 'file',
			},
			lottieLoop: {
				label: 'Should the animation loop?',
				widget: 'boolean',
			},
		},
	},
	FeatureComponent
)
