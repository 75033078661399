import { tint } from 'polished'

import colorTokens from './tokens'

const primitiveColors = {
	// Primary Palette
	primary: colorTokens.sky,
	primary500: tint(0.46, colorTokens.sky),
	primary600: tint(0.33, colorTokens.sky),
	primary700: tint(0.2, colorTokens.sky),

	// Accent Palette
	accent: colorTokens.fire,

	// Warning Palette
	warning: colorTokens.sun,

	// Success Palette
	positive: colorTokens.royal,

	mono300: colorTokens.whiteSmoke,
	mono500: colorTokens.smoke,
}
export default primitiveColors
