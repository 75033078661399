import { debounce } from 'lodash'
import { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { HorizontalScrollContainer } from './styled-components'

const RESIZE_DEBOUNCER_TIMEOUT = 100 // ms
const WHEEL_SCROLL_HORIZONTALLY_THRESHOLD = 5 // pixels
const TOUCH_SCROLL_HORIZONTALLY_THRESHOLD = 20 // pixels
const UNLOCK_SCROLLER_DELAY = 80 // ms
const SCROLLER_CONTAINER_CLASSNAME = 'js-horizontal-scroll-container'

function isAtStartOfScrollbar(scrollEl: HTMLDivElement) {
	return scrollEl.scrollLeft === 0
}

function isAtEndOfScrollbar(scrollEl: HTMLDivElement, scrollWidth: number) {
	return scrollEl.scrollLeft + scrollEl.offsetWidth >= scrollWidth
}
function hasReachedStartOrEndOfScrollbar(
	scrollEl: HTMLDivElement,
	scrollWidth: number
) {
	return (
		isAtStartOfScrollbar(scrollEl) || isAtEndOfScrollbar(scrollEl, scrollWidth)
	)
}

interface HorizontalLockScrollerProps {
	isMouseOverParent: boolean
}
const HorizontalLockScroller: React.FC<HorizontalLockScrollerProps> = ({
	children,
	isMouseOverParent,
}) => {
	const ref = useRef<HTMLDivElement>(null)
	const containerScrollWidth = useRef<number | undefined>()
	const currentPageX = useRef<number>()
	const currentPageY = useRef<number>()
	const scrollerLocked = useRef<boolean>(false)
	const scrollerLockedTimeout = useRef<NodeJS.Timeout>()
	const { ref: setRef, inView } = useInView({
		threshold: 1,
	})

	useEffect(() => {
		if (ref.current && inView) {
			const handleWheel = (event: WheelEvent) => {
				const deltaY = event.deltaY
				const isScrollingHorizontally =
					Math.abs(event.deltaX) >= WHEEL_SCROLL_HORIZONTALLY_THRESHOLD &&
					Math.abs(event.deltaY) <= WHEEL_SCROLL_HORIZONTALLY_THRESHOLD

				const container = ref.current

				if (!containerScrollWidth.current && container?.scrollWidth! > 0) {
					containerScrollWidth.current = container?.scrollWidth!
				}

				if (!isScrollingHorizontally) {
					container?.scrollBy(deltaY, 0)

					const hasReachedEdges = hasReachedStartOrEndOfScrollbar(
						container!,
						containerScrollWidth.current!
					)

					if (hasReachedEdges) {
						scrollerLockedTimeout.current = setTimeout(() => {
							scrollerLocked.current = false
						}, UNLOCK_SCROLLER_DELAY)
					} else {
						if (scrollerLockedTimeout.current) {
							clearTimeout(scrollerLockedTimeout.current)
						}

						scrollerLocked.current = true
					}

					if (isMouseOverParent && scrollerLocked.current) {
						event.preventDefault()
					}
				}
			}

			const handleTouchStart = (event: TouchEvent) => {
				// We only like to have scroll behaviour when the touch is inside
				// scroll container
				if (
					!(event.target as HTMLElement).closest(
						`.${SCROLLER_CONTAINER_CLASSNAME}`
					)
				) {
					return
				}

				const pageY = event.touches[0].pageY
				const pageX = event.touches[0].pageX
				currentPageY.current = pageY
				currentPageX.current = pageX
			}

			const handleTouchMove = (event: TouchEvent) => {
				// We only like to have scroll behaviour when the touch is inside
				// scroll container
				if (
					!(event.target as HTMLElement).closest(
						`.${SCROLLER_CONTAINER_CLASSNAME}`
					)
				) {
					return
				}
				const pageY = event.touches[0].pageY
				const pageX = event.touches[0].pageX
				const container = ref.current

				const isScrollingHorizontally =
					Math.abs(currentPageX.current! - pageX) >=
					TOUCH_SCROLL_HORIZONTALLY_THRESHOLD

				const deltaY = currentPageY.current ? pageY - currentPageY.current : 0
				const scrollByValue = -deltaY * 2

				if (!containerScrollWidth.current && container?.scrollWidth! > 0) {
					containerScrollWidth.current = container?.scrollWidth!
				}

				const shouldScroll =
					container &&
					!isScrollingHorizontally &&
					!(
						(isAtStartOfScrollbar(container!) &&
							Math.sign(scrollByValue) === -1) ||
						(isAtEndOfScrollbar(container!, containerScrollWidth.current!) &&
							Math.sign(scrollByValue) === 1)
					)

				if (shouldScroll) {
					container?.scrollBy(scrollByValue, 0)

					if (
						!hasReachedStartOrEndOfScrollbar(
							container!,
							containerScrollWidth.current!
						)
					) {
						event.preventDefault()
					}
					currentPageY.current = pageY
				}
			}

			const handleResize = debounce(() => {
				const container = ref.current
				containerScrollWidth.current = container?.scrollWidth!
			}, RESIZE_DEBOUNCER_TIMEOUT)

			window.addEventListener('wheel', handleWheel, { passive: false })
			window.addEventListener('touchmove', handleTouchMove, { passive: false })
			window.addEventListener('touchstart', handleTouchStart, {
				passive: false,
			})

			window.addEventListener('resize', handleResize)

			return () => {
				window.removeEventListener('wheel', handleWheel)
				window.removeEventListener('touchmove', handleTouchMove)
				window.removeEventListener('touchstart', handleTouchStart)
				window.removeEventListener('resize', handleResize)
			}
		}
	}, [inView, containerScrollWidth, isMouseOverParent])

	return (
		<div ref={setRef}>
			<HorizontalScrollContainer
				className={SCROLLER_CONTAINER_CLASSNAME}
				ref={ref}
			>
				{children}
			</HorizontalScrollContainer>
		</div>
	)
}

export default HorizontalLockScroller
