import { makeCMSComponent } from '../CMSComponent'

import { default as SuccessModalHeaderComponent } from './SuccessModalHeader'

export const SuccessModalHeader = makeCMSComponent(
	{
		tag: 'SuccessModalHeader',
		label: 'SuccessModal Header - shown after question form submit',
		fields: {},
	},
	SuccessModalHeaderComponent
)

// Cannot be used directly in mdx files so we should create custom success
// modal container
export { default as SuccessModal } from './SuccessModal'
