import { makeCMSComponent, PropsFromCMSComponent } from '../CMSComponent'

import { default as HeroComponent } from './Hero'
import { default as HeroMediaComponent } from './HeroMedia'

export const Hero = makeCMSComponent(
	{
		tag: 'Hero',
		label: 'Hero',
		fields: {
			name: {
				label: 'Name',
				widget: 'string',
			},
			title: {
				label: 'Title',
				widget: 'string',
			},
			buttonText: {
				label: 'CTA Button text',
				widget: 'string',
			},
			buttonToggleTargetName: {
				label: 'Name of the target it opens (e.g. modal)',
				widget: 'string',
			},
			imageSrc: {
				label: 'URL to image',
				widget: 'image',
			},
			subtitle: {
				label: 'Hero Subtitle',
				widget: 'text',
			},
		},
	},
	HeroComponent
)

export const HeroMedia = makeCMSComponent(
	{
		tag: 'HeroMedia',
		label: 'HeroMedia - image or video component used in hero',
		fields: {},
	},
	HeroMediaComponent
)

export type HeroProps = PropsFromCMSComponent<typeof Hero>

export default Hero
