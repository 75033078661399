import {
	FC,
	Children,
	useMemo,
	ReactElement,
	cloneElement,
	useState,
	useCallback,
	useEffect,
} from 'react'
import Fade from 'react-reveal/Fade'

import useTrackSectionViewed from '../hooks/useTrackSectionViewed'
import getConfig from '../infra/config/config'
import AndroidIcon from '../SVGS/Android'
import AppleIcon from '../SVGS/Apple'
import DesktopIcon from '../SVGS/Desktop'

import HeroLoading from './HeroLoading'
import HeroMedia from './HeroMedia'
import {
	HeroContainer,
	HeroTitle,
	HeroAction,
	HeroImage,
	HeroButton,
	HeroContent,
	HeroSubtitle,
	HeroIconImage,
} from './styled-components'

export interface HeroProps {
	imageSrc: string
	name: string
	title: string
	buttonText: string
	buttonToggleTargetName: string
	subtitle: string
}

const renderSubtitle = (subtitle: string) => {
	const subtitleBrokenByLine = subtitle.split('\n')
	const numberOfLines = subtitleBrokenByLine.length

	return subtitleBrokenByLine.map((lineText: string, index: number) => {
		const Component = index === numberOfLines - 1 ? 'span' : 'div'
		return <Component key={index}>{lineText.trim()}</Component>
	})
}

const Hero: FC<HeroProps> = ({ imageSrc = '', ...props }) => {
	const [isHeroShown, setHeroShown] = useState<boolean>(false)
	const [shouldShowCta, setShowCta] = useState<boolean>(false)
	const [isHeroActionShown, setHeroActionShown] = useState<boolean>(false)

	const shouldShowAppIcons = !getConfig().unlaunchVariationEnabled

	const { ref } = useTrackSectionViewed({
		sectionName: props.name,
	})

	const handleMediaLoad = useCallback(() => {
		setHeroShown(true)
		setHeroActionShown(!!getConfig().unlaunchVariationEnabled)
	}, [setHeroShown, setHeroActionShown])

	useEffect(() => {
		setShowCta(!getConfig().hideAuthLinks)
	}, [setShowCta])

	const heroMedia = useMemo(() => {
		let media = null

		if (imageSrc !== '') {
			media = (
				<HeroMedia>
					<HeroImage src={imageSrc} />
				</HeroMedia>
			)
		} else if (props.children) {
			const childrenArray = Children.toArray(props.children)

			media = childrenArray.find(
				child => (child as ReactElement).props.mdxType === 'HeroMedia'
			)
		}

		if (media !== null) {
			return cloneElement(media as ReactElement, {
				onLoad: handleMediaLoad,
			})
		}
	}, [imageSrc, props.children, handleMediaLoad])

	return (
		<HeroContainer ref={ref}>
			<HeroLoading $isShown={!isHeroShown}></HeroLoading>
			<HeroContent>
				<Fade
					bottom
					duration={1000}
					outEffect
					fraction={0.1}
					when={isHeroShown}
					delay={250}
				>
					<HeroTitle>{props.title}</HeroTitle>
					<HeroAction $isAlwaysShown={isHeroActionShown}>
						{shouldShowCta && (
							<HeroButton
								data-tracking
								targetName={props.buttonToggleTargetName}
							>
								{props.buttonText}
							</HeroButton>
						)}

						{props.subtitle && (
							<HeroSubtitle $hasMarginLeft={!!shouldShowCta}>
								<>
									{renderSubtitle(props.subtitle)}
									{shouldShowAppIcons && (
										<>
											<HeroIconImage component={AppleIcon} />
											<HeroIconImage component={AndroidIcon} />
											<HeroIconImage component={DesktopIcon} />
										</>
									)}
								</>
							</HeroSubtitle>
						)}
					</HeroAction>
				</Fade>
			</HeroContent>

			<Fade
				right
				duration={400}
				outEffect
				fraction={0.1}
				when={isHeroShown}
				delay={250}
			>
				{heroMedia}
			</Fade>
		</HeroContainer>
	)
}

export default Hero
