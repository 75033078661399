import { FC } from 'react'
import styled, { keyframes } from 'styled-components'

const svgPaintAnimation = keyframes`
    50% {
        stroke-dashoffset: 2000;
	}

    100% {
        stroke-dashoffset: 2000;
	}
`

const SVGWrapper = styled.div`
	width: 120px;
	color: ${({ theme }) => theme.colors.mono700};

	.paint-animation {
		stroke-dasharray: 1000;
		stroke-dashoffset: 1000;
		animation: ${svgPaintAnimation} 4s cubic-bezier(0.76, 0.02, 0.35, 0.99);
		animation-direction: alternate;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
	}

	${({ theme }) => theme.mediaQuery.medium} {
		width: 184px;
	}
`
export const HeroLoadingOverlay = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: ${({ theme }) => theme.colors.backgroundPrimary};
	pointer-events: none;
	transition: opacity 750ms ease 0.15s;
	z-index: ${({ theme }) => theme.zIndex.heroLoaderOverlay};
`

const HeroLoadingAnimation: FC = () => (
	<SVGWrapper>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 188 161"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M182.579 56.7339C182.579 26.0846 141.828 1.23047 91.5594 1.23047C41.2902 1.23047 0.539307 26.0522 0.539307 56.7339C0.539307 83.4441 31.4587 105.74 72.6518 111.05L91.5594 159.862L110.467 111.05C151.66 105.74 182.579 83.4441 182.579 56.7339Z"
				fill="none"
				stroke="currentColor"
				strokeWidth="3"
				strokeLinejoin="round"
				className="paint-animation"
				transform="translate(2, 0)"
			/>
			<path
				d="M3.52986 6.8273C5.17489 6.8273 6.50845 5.49374 6.50845 3.84871C6.50845 2.20368 5.17489 0.870117 3.52986 0.870117C1.88483 0.870117 0.55127 2.20368 0.55127 3.84871C0.55127 5.49374 1.88483 6.8273 3.52986 6.8273Z"
				fill="currentColor"
				strokeWidth="3"
				strokeLinejoin="round"
				transform="translate(82, 44)"
				className="paint-animation"
			/>

			<path
				d="M3.52986 6.8273C5.17489 6.8273 6.50845 5.49374 6.50845 3.84871C6.50845 2.20368 5.17489 0.870117 3.52986 0.870117C1.88483 0.870117 0.55127 2.20368 0.55127 3.84871C0.55127 5.49374 1.88483 6.8273 3.52986 6.8273Z"
				fill="currentColor"
				strokeWidth="1.0792"
				strokeLinejoin="round"
				transform="translate(106, 44)"
				className="paint-animation"
			/>
			<path
				d="M45.9311 1.2666C45.9311 7.19997 43.5739 12.8905 39.3784 17.086C35.1829 21.2815 29.4927 23.6384 23.5593 23.6384C17.6259 23.6384 11.9352 21.2815 7.73963 17.086C3.5441 12.8905 1.1875 7.19997 1.1875 1.2666"
				stroke="currentColor"
				strokeWidth="3"
				strokeMiterlimit="10"
				strokeLinecap="round"
				transform="translate(74, 56)"
				className="paint-animation"
			/>
		</svg>
	</SVGWrapper>
)

const HeroLoading: FC<{ $isShown: boolean }> = ({ $isShown }) => (
	<HeroLoadingOverlay style={{ opacity: $isShown ? '1' : '0' }}>
		<HeroLoadingAnimation />
	</HeroLoadingOverlay>
)
export default HeroLoading
