import { useEffect, useState } from 'react'
import {
	default as ReactLottie,
	LottieProps as ReactLottieProps,
} from 'react-lottie'

import { loadLottieFromAssetServer } from '../infra/loadLottie/loader'

type AnimationData = any

interface LottieProps {
	src: string
	isStopped?: boolean
	isPaused?: boolean
	options?: Partial<ReactLottieProps['options']>
}
const Lottie: React.FC<LottieProps> = ({
	src,
	isStopped = true,
	isPaused = false,
	options,
}) => {
	const [animationData, setAnimationData] = useState<AnimationData>()

	useEffect(() => {
		if (!animationData) {
			loadLottieFromAssetServer(src).then(setAnimationData)
		}
	}, [animationData, src])

	return (
		<ReactLottie
			width={'100%'}
			isStopped={isStopped}
			isPaused={isPaused}
			isClickToPauseDisabled={true}
			options={{
				...options,
				animationData,
			}}
		></ReactLottie>
	)
}

export default Lottie
