import { useState, useCallback } from 'react'

import useTrackSectionViewed from '../hooks/useTrackSectionViewed'
import { HorizontalLockScroller } from '../HorizontalLockScroller'
import { PageSection } from '../PageSection'
import { H2 } from '../Typography'

import { MissionsItemContainer } from './styled-components'
export interface MissionProps {
	title: string
	items: string
}

const Mission: React.FC<MissionProps> = ({ title, children }) => {
	const { ref } = useTrackSectionViewed({
		sectionName: 'our-app/Mission',
	})

	const [isMouseOver, setMouseOver] = useState<boolean>(false)

	const handleMouseEnter = useCallback(() => setMouseOver(true), [setMouseOver])
	const handleMouseLeave = useCallback(
		() => setMouseOver(false),
		[setMouseOver]
	)
	return (
		<PageSection
			$color="backgroundInversePrimary"
			ref={ref}
			onMouseLeave={handleMouseLeave}
			onMouseEnter={handleMouseEnter}
		>
			<H2
				color="white"
				maxWidth={['100%', '80%', '620px']}
				overrides={{
					Block: {
						style: ({ $theme }) => ({
							...$theme.typography.HeadingMedium,
							marginTop: '0',
							marginBottom: $theme.sizing.scale2400,
							[$theme.mediaQuery.medium]: {
								marginBottom: $theme.sizing.scale3200,
								...$theme.typography.HeadingXLarge,
							},
							[$theme.mediaQuery.large]: {
								...$theme.typography.HeadingXXLarge,
							},
						}),
					},
				}}
			>
				{title}
			</H2>
			<MissionsItemContainer>
				<HorizontalLockScroller isMouseOverParent={isMouseOver}>
					{children}
				</HorizontalLockScroller>
			</MissionsItemContainer>
		</PageSection>
	)
}

export default Mission
