import { useMemo, useCallback, useContext, useEffect } from 'react'

import {
	JoinWaitlistContext,
	JoinWaitlistActions,
} from '../context/JoinWaitlistProvider'
import { GenericFormQuestions } from '../domain/GenericFormQuestions'
import { createAnswerGenericFormUseCase } from '../useCases/answerGenericForm'

import useAsyncSafeState from './useAsyncSafeState'

interface WaitlistFollowUpSubmitState {
	idle: 'idle'
	submitting: 'submitting'
	submitted: 'submitted'
}

function useWaitlistFollowUp(formVersion?: string) {
	const answerFormUsecase = useMemo(
		() => createAnswerGenericFormUseCase('Waitlist', formVersion || '1'),
		[formVersion]
	)
	const { dispatch, waitlistEmail, followUpSubmitted } =
		useContext(JoinWaitlistContext)

	const [submitStatus, setSubmitStatus] =
		useAsyncSafeState<keyof WaitlistFollowUpSubmitState>('idle')
	const [error, setError] = useAsyncSafeState<Error | undefined>()

	useEffect(() => {
		if (followUpSubmitted && submitStatus !== 'submitted') {
			setSubmitStatus('submitted')
		}
	}, [followUpSubmitted, setSubmitStatus, submitStatus])

	const submitAnswers = useCallback(
		async (questionsData: GenericFormQuestions) => {
			try {
				if (!waitlistEmail) {
					return
				}

				setError(undefined)
				setSubmitStatus('submitting')

				const { status, error } = await answerFormUsecase.execute(
					waitlistEmail,
					questionsData
				)

				if (!error && status === 200) {
					setSubmitStatus('submitted')

					dispatch!({
						type: JoinWaitlistActions.FollowUpSubmitted,
					})
				} else {
					throw new Error(error)
				}
			} catch (error) {
				setError(error)
			}
		},
		[answerFormUsecase, waitlistEmail, dispatch, setError, setSubmitStatus]
	)

	return useMemo(
		() => ({
			submit: submitAnswers,
			state: submitStatus,
			error,
		}),
		[submitAnswers, submitStatus, error]
	)
}

export default useWaitlistFollowUp
