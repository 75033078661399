import { merge } from 'lodash'

import { FetchResult } from './types'
import { prepareRequestInitForBody } from './utils/parseRequestBody'
import { parseResponseContent } from './utils/parseResponse'
import standardizedFetch from './utils/standardizedFetch'
const RESPONSE_NOT_OK_ERROR_MESSAGE = 'Request failed'

enum Method {
	Get = 'GET',
	Post = 'POST',
	Put = 'PUT',
	Patch = 'PATCH',
	Delete = 'DELETE',
}

type ExposedRequestInit = Omit<RequestInit, 'method' | 'body'>

export class FetchManager {
	// Throws on network error
	private async _request(
		url: string,
		requestInit: RequestInit
	): Promise<FetchResult> {
		const response = await standardizedFetch(url, requestInit)
		const { status } = response
		const { data, parsingError } = await parseResponseContent(response)

		if (parsingError) {
			return {
				status,
				data,
				error: parsingError,
			}
		} else if (!response.ok) {
			return {
				status,
				data,
				error: RESPONSE_NOT_OK_ERROR_MESSAGE,
			}
		} else {
			return {
				status,
				data,
				error: undefined,
			}
		}
	}

	private static _buildRequestInit(params: {
		method: Method
		body?: unknown
		customRequestInit?: ExposedRequestInit
	}) {
		// Automatically stringify plain object bodies & set the Content-type header
		const bodyRequestInit = prepareRequestInitForBody(params.body)
		const combinedRequestInit = merge(
			{
				method: params.method,
			},
			bodyRequestInit,
			params.customRequestInit
		)
		return combinedRequestInit
	}

	get(url: string, requestInit?: ExposedRequestInit): Promise<FetchResult> {
		const finalRequestInit = FetchManager._buildRequestInit({
			method: Method.Get,
			customRequestInit: requestInit,
		})
		return this._request(url, finalRequestInit)
	}

	post(
		url: string,
		body?: unknown,
		requestInit?: ExposedRequestInit
	): Promise<FetchResult> {
		const finalRequestInit = FetchManager._buildRequestInit({
			method: Method.Post,
			body: body,
			customRequestInit: requestInit,
		})
		return this._request(url, finalRequestInit)
	}

	put(
		url: string,
		body?: unknown,
		requestInit?: ExposedRequestInit
	): Promise<FetchResult> {
		const finalRequestInit = FetchManager._buildRequestInit({
			method: Method.Put,
			body: body,
			customRequestInit: requestInit,
		})
		return this._request(url, finalRequestInit)
	}

	patch(
		url: string,
		body?: unknown,
		requestInit?: ExposedRequestInit
	): Promise<FetchResult> {
		const finalRequestInit = FetchManager._buildRequestInit({
			method: Method.Patch,
			body: body,
			customRequestInit: requestInit,
		})
		return this._request(url, finalRequestInit)
	}

	delete(url: string, requestInit?: ExposedRequestInit): Promise<FetchResult> {
		const finalRequestInit = FetchManager._buildRequestInit({
			method: Method.Delete,
			customRequestInit: requestInit,
		})
		return this._request(url, finalRequestInit)
	}
}
