import { makeCMSComponent } from '../CMSComponent'

import { default as NotFoundPageComponent } from './NotFoundPage'

export const NotFoundPage = makeCMSComponent(
	{
		tag: 'NotFoundPage',
		label: 'NotFoundPage - 404 page',
		fields: {},
	},
	NotFoundPageComponent
)
