import { MouseEventHandler } from 'react'
import { useCallback } from 'react'

import { Button, ButtonProps } from '../Button'
import { useToggle, Toggle } from '../context/ToggleProvider'

interface ToggleProps {
	targetName: Toggle['name']
}

export type ToggleButtonProps = ButtonProps & ToggleProps

const ToggleButton: React.FC<ToggleButtonProps> = ({
	targetName,
	children,
	onClick,
	...props
}) => {
	const [, toggle] = useToggle({ name: targetName })

	const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
		event => {
			onClick?.(event)
			toggle()
		},
		[toggle, onClick]
	)
	return (
		<Button {...props} onClick={handleClick}>
			{children}
		</Button>
	)
}

export default ToggleButton
