import { Block } from 'baseui/block'
import { Drawer } from 'baseui/drawer'
import { ALIGN, StyledNavigationList } from 'baseui/header-navigation'
import { Menu } from 'baseui/icon'
import React, { useState, useMemo, Children, useCallback } from 'react'
import Fade from 'react-reveal/Fade'

import { EventNames, useTracking } from '../analytics'
import { KIND, SHAPE, SIZE, Button } from '../Button'
import getConfig from '../infra/config/config'
import { ToggleButton } from '../ToggleButton'

import { Logo } from './Logo'
import { drawerOverrides } from './style-override'
import {
	MobileNavListItem,
	MobileLogoWrapper,
	MainNavItemsList,
	NavItemsWrapper,
	NavLink,
	MobileNav,
} from './styled-components'

function calculateDelay(index: number): number {
	return 200 + index * (350 / (index + 1))
}
interface MobileNavbarProps {
	signInUrl?: string
}

const MobileNavbar: React.FC<MobileNavbarProps> = ({ signInUrl, children }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const handleJoinWaitlistClick = useCallback(() => {
		if (isOpen) {
			setIsOpen(false)
		}
	}, [setIsOpen, isOpen])

	const navItemCount = useMemo(
		() => Children.toArray(children).length,
		[children]
	)

	const { trackEvent } = useTracking()
	const shouldShowAuthLinks = !getConfig().hideAuthLinks
	const shouldShowDrawerTrigger = shouldShowAuthLinks || navItemCount > 0

	const navItemsMobile = useMemo(
		() => (
			<>
				<MainNavItemsList>
					{Children.map(children, (item, index) => (
						<Fade bottom outEffect delay={calculateDelay(index)} duration={350}>
							{item}
						</Fade>
					))}
				</MainNavItemsList>
				<Fade
					bottom
					outEffect
					delay={calculateDelay(navItemCount)}
					duration={350}
				>
					{shouldShowAuthLinks && (
						<Block marginTop={['scale800', 'scale800']}>
							<Button
								onClick={() => {
									trackEvent(EventNames.LinkClicked, {
										link_url: signInUrl!,
									})
									window.location.assign(signInUrl!)
								}}
								kind={KIND.tertiary}
								shape={SHAPE.pill}
							>
								Sign in
							</Button>
						</Block>
					)}
				</Fade>
				<Fade
					bottom
					outEffect
					delay={calculateDelay(navItemCount + 1)}
					duration={350}
				>
					{shouldShowAuthLinks && (
						<Block marginTop={['scale800', 'scale800']}>
							<ToggleButton
								data-tracking
								targetName="join-waitlist"
								kind={KIND.secondary}
								shape={SHAPE.pill}
								onClick={handleJoinWaitlistClick}
							>
								Join waitlist
							</ToggleButton>
						</Block>
					)}
				</Fade>
			</>
		),
		[
			children,
			signInUrl,
			navItemCount,
			trackEvent,
			handleJoinWaitlistClick,
			shouldShowAuthLinks,
		]
	)
	return (
		<MobileNav>
			<MobileLogoWrapper $align={ALIGN.left}>
				<NavLink href="/" title="Sphere">
					<Logo />
				</NavLink>
			</MobileLogoWrapper>
			<StyledNavigationList $align={ALIGN.right}>
				{shouldShowAuthLinks && (
					<MobileNavListItem>
						<Block display={['none', 'flex', 'flex']}>
							<ToggleButton
								data-tracking
								targetName="join-waitlist"
								size={SIZE.compact}
								shape={SHAPE.pill}
							>
								Join waitlist
							</ToggleButton>
						</Block>
					</MobileNavListItem>
				)}
				{shouldShowDrawerTrigger && (
					<MobileNavListItem>
						<Button
							kind={KIND.secondary}
							shape={SHAPE.circle}
							onClick={() => setIsOpen(true)}
							size={SIZE.compact}
						>
							<Menu color="black" size={28} />
						</Button>
					</MobileNavListItem>
				)}
			</StyledNavigationList>
			<Drawer
				overrides={drawerOverrides}
				isOpen={isOpen}
				autoFocus
				onClose={() => setIsOpen(false)}
			>
				<NavItemsWrapper>{navItemsMobile}</NavItemsWrapper>
			</Drawer>
		</MobileNav>
	)
}

export default MobileNavbar
