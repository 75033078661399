import { FormControlOverrides } from 'baseui/form-control'
import { ModalOverrides } from 'baseui/modal'
import { Override } from 'baseui/overrides'

import { Theme } from '../styles/themes/default'

export const modalStyleOverrides: ModalOverrides | { Root?: Override<any> } = {
	Dialog: {
		style: ({ $theme }) => ({
			borderTopLeftRadius: $theme.borders.radius400,
			borderTopRightRadius: $theme.borders.radius400,
			borderBottomRightRadius: $theme.borders.radius400,
			borderBottomLeftRadius: $theme.borders.radius400,
			borderWidth: '1px',
			borderTopStyle: 'solid',
			borderLeftStyle: 'solid',
			borderRightStyle: 'solid',
			borderBottomStyle: 'solid',
			borderTopColor: $theme.colors.black,
			borderLeftColor: $theme.colors.black,
			borderRightColor: $theme.colors.black,
			borderBottomColor: $theme.colors.black,
		}),
	},
	Close: {
		style: () => ({ display: 'none' }),
	},
	Root: {
		style: ({ $theme }: { $theme: Theme }) => ({
			zIndex: $theme.zIndex.modal,
		}),
	},
}

export const formControlStyleOverrides: FormControlOverrides = {
	ControlContainer: {
		style: ({ $theme }) => ({
			marginBottom: $theme.sizing.scale800,
		}),
	},
}
