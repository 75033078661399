import { useRef, useCallback, useMemo } from 'react'

import { SegmentClient } from '@sphere/segment-proxy'

import { EventNames } from '..'
import {
	ButtonClickedEvent,
	LinkClickedEvent,
	SectionViewedEvent,
} from '../types'

export type TrackingOptions = {
	once?: EventNames[]
}

type EventData = ButtonClickedEvent | LinkClickedEvent | SectionViewedEvent
interface UseTrackingValue {
	trackEvent(eventName: EventNames, eventData?: EventData): void
}

function logEvent(eventName: EventNames, eventData?: EventData) {
	if (process.env.NODE_ENV === 'development') {
		// eslint-disable-next-line no-console
		console.log('[dev] Tracking event', { eventName, eventData })
	}
}

function useTracking(trackingOptions?: TrackingOptions): UseTrackingValue {
	const eventsTrackState = useRef<Partial<Record<EventNames, boolean>>>({})

	const trackEvent = useCallback(
		(eventName: EventNames, eventData?: EventData) => {
			if (trackingOptions?.once?.includes(eventName)) {
				const isEventTracked = eventsTrackState.current[eventName as EventNames]

				if (!isEventTracked) {
					SegmentClient.track(eventName, eventData)
					logEvent(eventName, eventData)

					eventsTrackState.current = {
						...eventsTrackState.current,
						[eventName as EventNames]: true,
					}
				}
			} else {
				logEvent(eventName, eventData)
				SegmentClient.track(eventName, eventData)
			}
		},
		[trackingOptions]
	)

	return useMemo(
		() => ({
			trackEvent,
		}),
		[trackEvent]
	)
}

export { useTracking }
