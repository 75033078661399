import { useCallback, useState } from 'react'

import {
	ShapeContainer,
	Shape,
	ShapeHeader,
	ShapeText,
	ShapeCTAText,
} from './styled-components'

export interface MissionShapeItemProps {
	header: string
	ctaText: string
	text: string
	shapeUrl: string
}

const MissionShapeItem: React.FC<MissionShapeItemProps> = ({
	header,
	ctaText,
	text,
	shapeUrl,
}) => {
	const [flipped, setFlipped] = useState<boolean>(false)

	const handleShapeClick = useCallback(
		() => setFlipped(prevValue => !prevValue),
		[]
	)
	return (
		<ShapeContainer onClick={handleShapeClick}>
			<Shape $shapeImageUrl={shapeUrl} flipped={flipped}>
				<ShapeHeader>{header}</ShapeHeader>
				<ShapeCTAText>{ctaText}</ShapeCTAText>
				<ShapeText>{text}</ShapeText>
			</Shape>
		</ShapeContainer>
	)
}

export default MissionShapeItem
