import { withStyle } from 'baseui'

import { ModalHeader as DefaultHeader } from '../Modal'

export const ModalHeader = withStyle(DefaultHeader, ({ $theme }) => ({
	...$theme.typography.HeadingLarge,
	textAlign: 'center',
	maxWidth: '930px',
	marginTop: '0',
	marginLeft: 'auto',
	marginRight: 'auto',
	[$theme.mediaQuery.medium]: {
		...$theme.typography.HeadingXLarge,
	},
}))

ModalHeader.displayName = 'JoinWaitlistModalHeader'
export default ModalHeader
