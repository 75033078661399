import { ModalOverrides } from 'baseui/modal'
import { Override } from 'baseui/overrides'

import { Theme } from '../styles/themes/default'

import CloseButton from './CloseButton'

export const hideCloseStyleOverride = {
	Close: {
		style: () => ({
			display: 'none',
		}),
	},
}

export const showCloseStyleOverride: Pick<ModalOverrides, 'Close'> = {
	Close: CloseButton,
}

export const modalStyleOverrides: ModalOverrides | { Root?: Override<any> } = {
	Root: {
		style: ({ $theme }: { $theme: Theme }) => ({
			zIndex: $theme.zIndex.modal,
		}),
	},
	Dialog: {
		style: ({ $theme }) => ({
			paddingTop: $theme.sizing.scale2400,
			paddingBottom: $theme.sizing.scale400,
			paddingLeft: $theme.sizing.scale400,
			paddingRight: $theme.sizing.scale400,
			marginLeft: '0',
			marginRight: '0',
			marginTop: '0',
			marginBottom: '0',
			[$theme.mediaQuery.medium]: {
				paddingTop: $theme.sizing.scale3200,
				paddingBottom: $theme.sizing.scale3200,
				paddingLeft: $theme.sizing.scale1200,
				paddingRight: $theme.sizing.scale1200,
			},
		}),
	},
}
