import { FC } from 'react'

const Zwerl: FC = () => (
	<svg
		width="126"
		height="126"
		viewBox="0 0 126 126"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M73.8266 0.481984C99.3117 4.67483 120.174 25.0064 125.002 50.4279C125.945 53.3175 126.428 63.8311 125.305 73.1643C121.038 98.6928 100.779 119.361 75.2214 124.259C65.7702 125.688 54.0943 124.797 52.4186 124.52C26.9272 120.302 7.11285 99.7631 1.20861 74.5651C0.173555 70.1519 -0.877279 55.6689 1.1739 50.7489C6.31131 25.1291 25.3873 6.00634 51.0238 0.74325C53.1602 0.305708 60.5602 -0.53475 73.8266 0.481984ZM47.4579 75.9281C47.8903 76.1138 48.3289 76.2806 48.7549 76.4852C62.9932 83.2813 79.3806 72.8369 79.1786 57.098C79.0019 43.4681 65.9943 33.6659 52.3334 36.8767C38.2466 40.1881 29.5275 53.2986 31.9889 67.4605C35.012 84.8552 51.1564 96.3666 68.5788 93.5588C89.668 90.1561 103.376 70.4887 99.217 49.5937C94.4646 25.7146 71.5104 10.9232 47.723 16.4129C22.4998 22.23 6.94244 45.7093 11.3414 71.3229C16.4536 101.095 45.8359 120.576 75.2688 113.528C95.2662 108.741 108.766 96.3981 115.696 77.2061C120.133 64.9171 120.455 52.3984 114.916 40.2637C106.819 22.5228 92.7574 11.8581 74.1012 6.87198C73.7919 6.79014 73.4227 6.62331 73.0156 7.02308C73.5679 7.20565 74.057 7.36304 74.543 7.52358C98.7405 15.5725 113.654 39.8985 109.801 65.0399C106.011 89.7846 81.6116 107.711 56.8082 103.978C36.3626 100.899 21.105 83.1459 21.124 62.4461C21.1429 41.1104 39.3511 24.3076 60.6675 25.957C78.3739 27.3263 91.6624 43.9434 89.0306 61.4199C86.8721 75.7455 72.7821 85.7712 58.629 82.9287C54.088 82.0159 50.2002 79.803 47.3191 76.0603C47.3664 76.0194 47.4106 75.9753 47.4579 75.9281Z"
			fill="#32CDFF"
		/>
	</svg>
)

export default Zwerl
