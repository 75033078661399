import type { GatsbyBrowser } from 'gatsby'

import { wrapRootElement, wrapPageElement } from './contextProviders'

const browser: GatsbyBrowser = {
	wrapRootElement,
	wrapPageElement,
}

export default browser
