import styled from 'styled-components'

import useTrackSectionViewed from '../hooks/useTrackSectionViewed'
import { PageSection } from '../PageSection'
import { H2 } from '../Typography'
export interface VideoSectionProps {
	title: string
}

export const Container = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.border};
	padding-top: ${({ theme }) => theme.sizing.scale1600};
	padding-bottom: ${({ theme }) => theme.sizing.scale1600};
`
const VideoSection: React.FC<VideoSectionProps> = ({ title, children }) => {
	const { ref } = useTrackSectionViewed({
		sectionName: 'our-app/VideoSection',
	})

	return (
		<Container>
			<PageSection $color="backgroundPrimary" ref={ref}>
				<H2
					overrides={{
						Block: {
							style: ({ $theme }) => ({
								...$theme.typography.HeadingMedium,
								marginTop: '0',
								marginBottom: '0',
								[$theme.mediaQuery.medium]: {
									...$theme.typography.HeadingXLarge,
								},
								[$theme.mediaQuery.large]: {
									...$theme.typography.HeadingXXLarge,
								},
							}),
						},
					}}
				>
					{title}
				</H2>
				{children}
			</PageSection>
		</Container>
	)
}

export default VideoSection
