import { withStyle } from 'baseui'

import {
	modalStyleOverrides,
	showCloseStyleOverride,
	hideCloseStyleOverride,
} from './style-override'

import { Modal, ModalBody as DefaultModalBody, ModalProps } from '.'

export const FullPageModalBody = withStyle(DefaultModalBody, ({ $theme }) => ({
	marginTop: $theme.sizing.scale600,
	marginBottom: $theme.sizing.scale1200,
	marginLeft: $theme.sizing.scale400,
	marginRight: $theme.sizing.scale400,
	[$theme.mediaQuery.medium]: {
		marginLeft: $theme.sizing.scale1200,
		marginRight: $theme.sizing.scale1200,
	},
}))

export const FullPageModal: React.FC<
	ModalProps & { showCloseButton?: boolean }
> = ({ overrides, showCloseButton = true, ...props }) => {
	const mergedOverrides = {
		...modalStyleOverrides,
		...overrides,
		...(showCloseButton ? showCloseStyleOverride : hideCloseStyleOverride),
	}

	return (
		<Modal closeable size="full" overrides={mergedOverrides} {...props}>
			{props.children}
		</Modal>
	)
}
