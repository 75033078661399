import { throttle } from 'lodash'
import { FC, useEffect, useState, useRef } from 'react'

import { AppNavBar, NavItem } from '../AppNavBar'
import { useTwitterBanner } from '../context/TwitterBannerContext'
import useTrackSectionViewed from '../hooks/useTrackSectionViewed'
import getConfig from '../infra/config/config'

import { StickyHeader, PositionedHeader } from './styled-components'

interface HeaderProps {
	menuLinks: readonly any[]
	currentPathname: string
}

const SCROLL_HANDLER_THROTTLE_TIMEOUT = 100

const isPageActive = (pageLink: string, currentPathname: string) =>
	pageLink === currentPathname

export const Header: FC<HeaderProps> = ({ menuLinks, currentPathname }) => {
	const [showHeaderBorder, setShowHeaderBorder] = useState<boolean>(false)
	const { ref } = useTrackSectionViewed({
		sectionName: 'Header',
	})

	const twitterBannerShownOnce = useRef<boolean>(false)

	const [pageOffsetY, setPageOffsetY] = useState(0)

	const HeaderComponent = getConfig().unlaunchVariationEnabled
		? PositionedHeader
		: StickyHeader

	const { isShown: isTwitterBannerShown } = useTwitterBanner()

	useEffect(() => {
		const handleScroll = throttle(() => {
			setShowHeaderBorder(window.pageYOffset > 0)
			setPageOffsetY(window.pageYOffset)
		}, SCROLL_HANDLER_THROTTLE_TIMEOUT)

		window.addEventListener('scroll', handleScroll)

		return () => window.removeEventListener('scroll', handleScroll)
	}, [setShowHeaderBorder, setPageOffsetY])

	useEffect(() => {
		if (isTwitterBannerShown) {
			twitterBannerShownOnce.current = true
		}

		// When the twitter banner hides we remove the padding-top on the header
		// so we need to adjust the page scroll to avoid jumping content
		if (!isTwitterBannerShown && twitterBannerShownOnce.current) {
			window.scrollTo(0, window.pageYOffset - pageOffsetY)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isTwitterBannerShown])

	return (
		<HeaderComponent
			$hasPaddingTop={isTwitterBannerShown}
			$showBorderBottom={showHeaderBorder}
			ref={ref}
		>
			<AppNavBar>
				{menuLinks.map(link => (
					<NavItem
						data-tracking
						key={link.name}
						isActive={isPageActive(link.link, currentPathname)}
						href={link.link}
					>
						{link.name}
					</NavItem>
				))}
			</AppNavBar>
		</HeaderComponent>
	)
}

export default Header
