import styled from 'styled-components'

export const Container = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

export const FeatureContentContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`
export const FeatureText = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	flex-basis: 100%;
	flex-shrink: 1;
	max-width: 100%;

	${({ theme }) => theme.mediaQuery.medium} {
		max-width: 30rem;
		flex-basis: 40%;
	}
`

export const FeatureLottie = styled.div`
	flex-grow: 1;
	flex-basis: 100%;
	max-width: 100%;
	margin-top: ${({ theme }) => theme.sizing.scale800};
	margin-left: 0;

	${({ theme }) => theme.mediaQuery.medium} {
		max-width: 37.5rem;
		margin-top: 0;
		margin-left: ${({ theme }) => theme.sizing.scale2400};
		flex-basis: calc(60% - ${({ theme }) => theme.sizing.scale2400});
	}
`
