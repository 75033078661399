import styled from 'styled-components'

export const QuestionContainer = styled.div`
	position: relative;
	box-sizing: border-box;
	padding: ${({ theme }) =>
		`${theme.sizing.scale800} ${theme.sizing.scale1000} ${theme.sizing.scale1600} ${theme.sizing.scale1000}`};
	border-radius: ${({ theme }) => theme.borders.radius400};
	border: 1px solid ${({ theme }) => theme.colors.border};
	max-width: 854px;
	flex-grow: 1;
	width: 100%;

	&:not(:first-child) {
		margin-top: ${({ theme }) => theme.sizing.scale1000};
	}
	${({ theme }) => theme.mediaQuery.small} {
		padding: ${({ theme }) =>
			`${theme.sizing.scale800} ${theme.sizing.scale1000} ${theme.sizing.scale2400} ${theme.sizing.scale1000}`};
	}

	${({ theme }) => theme.mediaQuery.medium} {
		padding: ${({ theme }) =>
			`${theme.sizing.scale1000} ${theme.sizing.scale1200}`};
	}
`

export const QuestionImage = styled.img`
	height: 50px;
	position: absolute;
	right: 30px;
	bottom: 26px;

	${({ theme }) => theme.mediaQuery.small} {
		height: 62px;
		right: 36px;
		bottom: 32px;
	}

	${({ theme }) => theme.mediaQuery.medium} {
		height: 100px;
		right: 72px;
		bottom: 64px;
	}
`
