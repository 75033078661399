import { SegmentClient } from '@sphere/segment-proxy'

import { EventNames } from '../analytics'
import { GenericFormQuestions } from '../domain/GenericFormQuestions'
import getConfig from '../infra/config/config'
import { GenericFormSubmissionRepo } from '../infra/repositories/GenericFormSubmissionRepo'

export default class AnswerGenericForm {
	constructor(
		private _genericFormSubmissionRepository: GenericFormSubmissionRepo,
		private _formName: string,
		private _formVersion: string
	) {}

	async execute(email: string, questionsData: GenericFormQuestions) {
		SegmentClient.track(EventNames.WaitlistQuestionFormSubmitted)

		return this._genericFormSubmissionRepository.submit(
			email,
			this._formName,
			this._formVersion,
			questionsData
		)
	}
}

export function createAnswerGenericFormUseCase(
	formName: string,
	version: string
) {
	const apiBaseUrl = getConfig().apiBaseUrl!

	return new AnswerGenericForm(
		new GenericFormSubmissionRepo(apiBaseUrl),
		formName,
		version
	)
}
