import { useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Fade from 'react-reveal/Fade'

import useTrackSectionViewed from '../hooks/useTrackSectionViewed'
import { Lottie } from '../Lottie'
import { PageSection } from '../PageSection'
import { H2, ParagraphLarge } from '../Typography'

import {
	Container,
	FeatureContentContainer,
	FeatureText,
	FeatureLottie,
} from './styled-components'
export interface FeatureProps {
	name: string
	header: string
	text: string
	lottieUrl: string
	lottieLoop: boolean
}

const Feature: React.FC<FeatureProps> = ({
	name,
	header,
	text,
	lottieUrl,
	lottieLoop = true,
}) => {
	const { ref, inView } = useInView({
		threshold: 0.5,
	})

	const { ref: containerRef } = useTrackSectionViewed({
		sectionName: name,
	})

	const [isRevealed, setRevealed] = useState<boolean>(false)

	const handleReveal = useCallback(() => {
		setRevealed(true)
	}, [])

	return (
		<Container ref={containerRef}>
			<PageSection $color="backgroundPrimary">
				<FeatureContentContainer>
					<FeatureText>
						<Fade bottom cascade fraction={0.5}>
							<H2
								overrides={{
									Block: {
										style: ({ $theme }) => ({
											...$theme.typography.HeadingMedium,
											marginTop: '0',
											marginBottom: $theme.sizing.scale800,
											[$theme.mediaQuery.medium]: {
												...$theme.typography.HeadingLarge,
												marginBottom: $theme.sizing.scale1600,
											},
											[$theme.mediaQuery.large]: {
												...$theme.typography.HeadingXXLarge,
											},
										}),
									},
								}}
							>
								{header}
							</H2>
							<ParagraphLarge
								overrides={{
									Block: {
										style: ({ $theme }) => ({
											position: 'relative',
											marginTop: '0',
											marginBottom: '0',
											...$theme.typography.ParagraphMedium,
											[$theme.mediaQuery.medium]: {
												...$theme.typography.ParagraphLarge,
											},
											[$theme.mediaQuery.large]: {
												bottom: '50%',
											},
										}),
									},
								}}
							>
								{text}
							</ParagraphLarge>
						</Fade>
					</FeatureText>

					<FeatureLottie ref={ref}>
						<Fade
							right
							delay={250}
							duration={750}
							fraction={0.5}
							onReveal={handleReveal}
						>
							<Lottie
								src={lottieUrl}
								options={{
									autoplay: false,
									loop: lottieLoop,
									rendererSettings: {
										preserveAspectRatio: 'xMidYMid slice',
									},
								}}
								isStopped={!(inView && isRevealed)}
							></Lottie>
						</Fade>
					</FeatureLottie>
				</FeatureContentContainer>
			</PageSection>
		</Container>
	)
}

export default Feature
