import { useMemo, useCallback, useContext, useEffect } from 'react'

import {
	JoinWaitlistContext,
	JoinWaitlistActions,
} from '../context/JoinWaitlistProvider'
import { JoinWaitlist } from '../domain/JoinWaitlist'
import { createJoinWaitlistUsecase } from '../useCases/joinWaitlist'

import useAsyncSafeState from './useAsyncSafeState'

interface JoinWaitlistSubmitState {
	idle: 'idle'
	submitting: 'submitting'
	submitted: 'submitted'
}

function useJoinWaitlist() {
	const joinWaitlistUseCase = useMemo(() => createJoinWaitlistUsecase(), [])
	const { dispatch, formSubmitted } = useContext(JoinWaitlistContext)

	const [joinStatus, setJoinStatus] =
		useAsyncSafeState<keyof JoinWaitlistSubmitState>('idle')
	const [error, setError] = useAsyncSafeState<Error | undefined>()

	useEffect(() => {
		if (formSubmitted && joinStatus !== 'submitted') {
			setJoinStatus('submitted')
		}
	}, [formSubmitted, setJoinStatus, joinStatus])

	const join = useCallback(
		async (data: JoinWaitlist) => {
			setError(undefined)
			setJoinStatus('submitting')
			try {
				const { error: joinWaitlistError } = await joinWaitlistUseCase.execute(
					data
				)

				// We ignore already exists and proceed
				if (!joinWaitlistError) {
					setJoinStatus('submitted')
					dispatch!({
						type: JoinWaitlistActions.FormSubmitted,
						payload: {
							email: data.email,
						},
					})
				} else {
					throw new Error(joinWaitlistError)
				}
			} catch (err) {
				setError(err)
			}
		},
		[joinWaitlistUseCase, dispatch, setError, setJoinStatus]
	)

	return useMemo(
		() => ({
			submit: join,
			state: joinStatus,
			error,
		}),
		[join, joinStatus, error]
	)
}

export default useJoinWaitlist
