import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

export interface Toggle {
	name: string
	initialValue?: boolean
}

interface TogglesState {
	[toggleName: string]: boolean
}

type UseToggle = (toggle: Toggle) => [boolean, () => void]
interface ToggleProviderContextValue {
	togglesState?: TogglesState
	getToggleState(name: Toggle['name']): boolean
	registerToggle?(toggle: Toggle): void
	toggle(name: Toggle['name']): void
}
const ToggleContext = createContext<ToggleProviderContextValue>({
	toggle() {},
	getToggleState() {
		return false
	},
})

export const ToggleProvider: React.FC = ({ children }) => {
	const [togglesState, setTogglesState] = useState<TogglesState>({})

	const registerToggle = useCallback(
		({ name, initialValue = false }: Toggle) => {
			setTogglesState(prevToggles => ({
				...prevToggles,
				[name]: initialValue,
			}))
		},
		[setTogglesState]
	)

	const getToggleState = useCallback(
		(name: Toggle['name']) => togglesState[name] ?? false,
		[togglesState]
	)

	const toggle = useCallback(
		(name: Toggle['name']) => {
			setTogglesState(prevToggles => ({
				...prevToggles,
				[name]: !prevToggles[name],
			}))
		},
		[setTogglesState]
	)
	return (
		<ToggleContext.Provider
			value={{ togglesState, registerToggle, getToggleState, toggle }}
		>
			{children}
		</ToggleContext.Provider>
	)
}

export const useToggle: UseToggle = ({ name, initialValue }) => {
	const context = useContext(ToggleContext)
	if (context === undefined) {
		throw new Error('useToggle must be used within a ToggleProvider')
	}

	useEffect(() => {
		if (initialValue !== undefined && name !== undefined) {
			context.registerToggle!({ name, initialValue })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, initialValue])

	const currentValue: boolean = context.getToggleState(name)

	return useMemo(
		() => [currentValue, context.toggle.bind(context, name)],
		[context, currentValue, name]
	)
}
