import { FC } from 'react'

import { SIZE, SHAPE, Button } from '../Button'
import { H2 } from '../Typography'

import { SectionContainer } from './styled-components'

const NotFoundPage: FC = ({ children }) => (
	<SectionContainer>
		<H2
			overrides={{
				Block: {
					style({ $theme }) {
						return {
							...$theme.typography.HeadingMedium,
							maxWidth: '1000px',
							marginLeft: 'auto',
							marginRight: 'auto',
							marginTop: '0',
							[$theme.mediaQuery.medium]: {
								...$theme.typography.HeadingXLarge,
							},
							[$theme.mediaQuery.large]: {
								...$theme.typography.HeadingXXLarge,
								maxWidth: '1200px',
							},
						}
					},
				},
			}}
		>
			{children}
		</H2>
		<Button $as="a" href="/" data-tracking size={SIZE.large} shape={SHAPE.pill}>
			Sphere Home
		</Button>
	</SectionContainer>
)

export default NotFoundPage
