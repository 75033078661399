import { CONTENT_TYPE } from './constants'

/*	Function to test if an object is a plain object, i.e. is constructed
 *	by the built-in Object constructor and inherits directly from Object.prototype
 *	or null. Some built-in objects pass the test, e.g. Math which is a plain object
 *	and some host or exotic objects may pass also.
 *	Modified from:
 *	https://stackoverflow.com/questions/5876332/how-can-i-differentiate-between-an-object-literal-other-javascript-objects
 */
function isPlainObject(obj: object): boolean {
	// Basic check for Type object that's not null
	if (typeof obj !== 'object' || obj === null) {
		return false
	}
	// If Object.getPrototypeOf supported, use it
	if (typeof Object.getPrototypeOf === 'function') {
		const proto = Object.getPrototypeOf(obj)
		const inheritsDirectlyFromObject = proto === Object.prototype
		const inheritsDirectlyFronNull = proto === null
		return inheritsDirectlyFromObject || inheritsDirectlyFronNull
	}
	// Otherwise, use internal class
	// This should be reliable as if getPrototypeOf not supported, is pre-ES5
	return Object.prototype.toString.call(obj) === '[object Object]'
}

export function shouldBodyBeStringified(body?: unknown): boolean {
	if (!body) {
		return false
	}
	if (typeof body === 'object' && isPlainObject(body as object)) {
		return true
	}
	return false
}

export function prepareRequestInitForBody(body?: unknown): RequestInit {
	if (shouldBodyBeStringified(body)) {
		return {
			body: JSON.stringify(body),
			headers: {
				// fetch is unable to differentiate between string and JSON bodies
				'Content-Type': CONTENT_TYPE.JSON,
			},
		}
	}

	// Rely on fetch's content-type defaults for different body types:
	// https://github.github.io/fetch/
	return { body: body as BodyInit }
}
