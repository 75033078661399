import styled from 'styled-components'

export const SectionContainer = styled.section`
	text-align: center;
	padding-top: ${({ theme }) => theme.sizing.scale3200};
	padding-bottom: ${({ theme }) => theme.sizing.scale3200};

	${({ theme }) => theme.mediaQuery.medium} {
		padding-top: ${({ theme }) => theme.sizing.scale5400};
		padding-bottom: ${({ theme }) => theme.sizing.scale5400};
	}
`
