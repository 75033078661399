import styled, { keyframes, css } from 'styled-components'

const arrowAnimation = keyframes`
	0% { 
		transform:translate(0, -10px); 
		opacity: 0;  
	}
	
	50% { 
		opacity: 1;  
	}
	
	100% { 
		transform:translate(0, 10px); 
		opacity: 0; 
	}
`

const iconAnimation = keyframes`
	0% { 
		transform: scale(0) translateZ(-200px);
		opacity: 0;  
	}
	
	100% { 
		transform: scale(1) translateZ(0px);
		opacity: 1; 
	}
`

export const fadeAnimation = keyframes`
	from { 
		transform: translateY(20px);
	}
	
	to { 
		transform: translateY(0);
		opacity: 1;
	}
	
	50% {
		opacity: 1
	}
`

export const TwitterFullPageBannerContainer = styled.section`
	display: flex;
	position: relative;
	background-color: ${({ theme }) => theme.colors.twitter};
	height: 100vh;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

export const TwitterFullPageBannerInner = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	margin-top: -50px;
	margin-top: max(-16%, -50px);
`

export const WidthAtWhichContentFitsOneLine = 477

export const TwitterStickyBannerContainer = styled.section`
	position: sticky;
	background-color: ${({ theme }) => theme.colors.twitter};
	z-index: 25;
	top: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	gap: 0;
	padding: ${({ theme }) =>
		`${theme.sizing.scale400} ${theme.sizing.scale400}`};

	${p => p.theme.mediaQuery.small} {
		gap: 1rem;
		justify-content: center;
		padding: ${({ theme }) =>
			`${theme.sizing.scale300} ${theme.sizing.scale400}`};
	}

	@media screen and (min-width: ${WidthAtWhichContentFitsOneLine}px) {
		gap: 1rem;
		justify-content: center;
		padding: ${({ theme }) =>
			`${theme.sizing.scale900} ${theme.sizing.scale400}`};
	}
`

export const ScrollToSphereText = styled.div`
	display: block;
	margin-bottom: 12px;
`
export const DownArrowAnimation = styled.div`
	animation: ${arrowAnimation} 1.2s ease-in-out infinite;
`

export const TwitterSphereIconContainer = styled.div(
	({ theme }) => css`
		flex-basis: 100%;
		${p => p.theme.mediaQuery.small} {
			flex-basis: auto;
		}
	`
)

export const TwitterSphereAnimatedIconContainer = styled.div(
	({ theme }) => css`
		width: 132px;
		animation: ${iconAnimation} 1s ease;
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
		animation-delay: 0.1s;
		opacity: 0;
		transform: scale(0);
		margin-bottom: ${theme.sizing.scale1200};
		${theme.mediaQuery.medium} {
			width: 160px;
			margin-bottom: ${theme.sizing.scale600};
		}
	`
)

export const BannerTextAnimation = styled.div`
	opacity: 0;
	animation: ${fadeAnimation} 3s;
	animation-fill-mode: forwards;
`

export const CtaAnimation = styled.div`
	opacity: 0;
	animation: ${fadeAnimation} 0.5s;

	animation-fill-mode: forwards;
	animation-delay: 0.25s;
`

export const ScrollToSphereContainer = styled.section`
	opacity: 0;
	animation: ${fadeAnimation} 0.25s;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
	position: absolute;
	width: 100%;
	text-align: center;
	bottom: 48px;
	color: white;
`

export const StickyBannerButtonContainer = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: row-reverse;

		${theme.mediaQuery.small} {
			min-width: 120px;
			margin-top: 0;
		}

		${theme.mediaQuery.medium} {
			min-width: 160px;
		}
	`
)
