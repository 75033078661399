import {
	useRef,
	useEffect,
	useState,
	useCallback,
	Dispatch,
	SetStateAction,
} from 'react'

function useAsyncSafeState<S>(
	initialState?: undefined
): [S | undefined, Dispatch<SetStateAction<S | undefined>>]

function useAsyncSafeState<S>(
	intialValue: S | (() => S)
): readonly [S, Dispatch<SetStateAction<S>>]

function useAsyncSafeState<S>(intialValue: any) {
	const mountedRef = useRef(false)
	const [state, setState] = useState<S>(intialValue)

	useEffect(() => {
		mountedRef.current = true
		return () => {
			mountedRef.current = false
		}
	}, [])

	const safeSetState = useCallback<typeof setState>(
		update => {
			if (mountedRef.current) {
				setState(update)
			}
		},
		[setState]
	)

	return [state, safeSetState] as const
}

export default useAsyncSafeState
