import styled from 'styled-components'

import { Colors } from '../styles/themes/default'

export interface SectionProps {
	$color: Colors
}

export const PageSection = styled.section<SectionProps>`
	position: relative;
	overflow-x: hidden;
	background-color: ${({ theme, $color }) => theme.colors[$color]};
	padding-top: ${({ theme }) => theme.sizing.scale800};
	padding-bottom: ${({ theme }) => theme.sizing.scale800};
	padding-left: ${({ theme }) => theme.sizing.scale800};
	padding-right: ${({ theme }) => theme.sizing.scale800};

	${({ theme }) => theme.mediaQuery.medium} {
		padding-top: ${({ theme }) => theme.sizing.scale2000};
		padding-bottom: ${({ theme }) => theme.sizing.scale2000};
		padding-left: ${({ theme }) => theme.sizing.scale1800};
		padding-right: ${({ theme }) => theme.sizing.scale1800};
	}

	${({ theme }) => theme.mediaQuery.large} {
		padding-top: ${({ theme }) => theme.sizing.scale2400};
		padding-bottom: ${({ theme }) => theme.sizing.scale2400};
	}
`

export default PageSection
