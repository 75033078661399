import { FormControlOverrides } from 'baseui/form-control'
import { InputOverrides } from 'baseui/input'

export const inputStyleOverrides: InputOverrides = {
	Root: {
		style: ({ $theme }) => ({
			borderTopWidth: '1px',
			borderBottomWidth: '1px',
			borderRightWidth: '1px',
			borderLeftWidth: '1px',
			borderTopLeftRadius: $theme.borders.radius100,
			borderBottomLeftRadius: $theme.borders.radius100,
			borderTopRightRadius: $theme.borders.radius100,
			borderBottomRightRadius: $theme.borders.radius100,
		}),
	},

	InputContainer: {
		style: ({ $theme }) => ({
			backgroundColor: $theme.colors.white,
			fontSize: $theme.sizing.scale800,
		}),
	},
	Input: {
		style: ({ $theme }) => ({
			...$theme.typography.ParagraphMedium,
			paddingTop: $theme.sizing.scale400,
			paddingBottom: $theme.sizing.scale400,
			paddingLeft: $theme.sizing.scale400,
			paddingRight: $theme.sizing.scale400,
			[$theme.mediaQuery.medium]: {
				paddingTop: $theme.sizing.scale800,
				paddingBottom: $theme.sizing.scale800,
				paddingLeft: $theme.sizing.scale800,
				paddingRight: $theme.sizing.scale800,
				...$theme.typography.ParagraphLarge,
			},
		}),
	},
}

export const formControlStyleOverrides: FormControlOverrides = {
	ControlContainer: {
		style: ({ $theme }) => ({
			marginBottom: $theme.sizing.scale400,
			[$theme.mediaQuery.medium]: {
				marginBottom: $theme.sizing.scale600,
			},
		}),
	},
}
