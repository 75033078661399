import { FC } from 'react'

const Cross: FC = () => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 21 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20.4796 1.09454L1.69709 19.8771M19.9714 19.6785L1.18884 0.895996"
			stroke="black"
		/>
	</svg>
)

export default Cross
