import { CONTENT_TYPE } from './constants'

const UNPARSEABLE_CONTENT_ERROR_MESSAGE = 'Unexpected content type' as const

interface ParsedResponseContent {
	data: unknown
	parsingError: string | undefined
}

export async function parseResponseContent(
	response: Response
): Promise<ParsedResponseContent> {
	// 204: NO CONTENT
	if (response.status === 204) {
		return {
			data: undefined,
			parsingError: undefined,
		}
	}
	const contentType = response.headers.get('content-type') ?? ''
	if (contentType.includes(CONTENT_TYPE.JSON)) {
		try {
			return {
				data: await response.json(),
				parsingError: undefined,
			}
		} catch (e) {
			return { data: undefined, parsingError: e.message }
		}
	} else if (
		contentType.includes(CONTENT_TYPE.TEXT) ||
		contentType.includes(CONTENT_TYPE.HTML)
	) {
		try {
			return {
				data: await response.text(),
				parsingError: undefined,
			}
		} catch (e) {
			return { data: undefined, parsingError: e.message }
		}
	} else {
		return {
			data: response,
			parsingError: UNPARSEABLE_CONTENT_ERROR_MESSAGE,
		}
	}
}
