import { BlockProps, Block } from 'baseui/block'
import { FC } from 'react'
import styled from 'styled-components'

import ZwerlLogo from '../SVGS/ZwerlLogo'
import { H3, ParagraphLarge } from '../Typography'

export interface ZwerlProps {
	title: string
	description: string
	bottomText: string
}

const Container = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	> * {
		font-family: 'Gotham', Arial, 'Helvetica Neue', Helvetica, sans-serif;
	}
`

export const ZwerlTitle: React.FC<BlockProps> = ({ children }) => (
	<H3
		overrides={{
			Block: {
				style: ({ $theme }) => ({
					marginTop: 0,
					marginBottom: $theme.sizing.scale1600,
					marginLeft: 'auto',
					marginRight: 'auto',
					fontFamily:
						"'GothamBold', Arial, 'Helvetica Neue', Helvetica, sans-serif",

					color: $theme.colors.zwerl,
					...$theme.typography.HeadingMedium,
					fontWeight: '700',
					[$theme.mediaQuery.medium]: {
						...$theme.typography.HeadingLarge,
						fontWeight: '700',
					},
					[$theme.mediaQuery.large]: {
						...$theme.typography.HeadingXLarge,
						fontWeight: '700',
						maxWidth: '730px',
					},
				}),
			},
		}}
	>
		{children}
	</H3>
)

const ZwerlDescription: React.FC = ({ children }) => (
	<ParagraphLarge
		overrides={{
			Block: {
				style: ({ $theme }) => ({
					marginTop: 0,
					marginBottom: $theme.sizing.scale800,
					marginLeft: 'auto',
					marginRight: 'auto',

					...$theme.typography.ParagraphMedium,
					[$theme.mediaQuery.medium]: {
						...$theme.typography.ParagraphLarge,
						maxWidth: '520px',
					},
				}),
			},
		}}
	>
		{children}
	</ParagraphLarge>
)

const ZwerlBottomText: React.FC = ({ children }) => (
	<ParagraphLarge
		overrides={{
			Block: {
				style: ({ $theme }) => ({
					marginTop: 0,
					marginBottom: 0,
					marginLeft: 'auto',
					marginRight: 'auto',

					...$theme.typography.ParagraphMedium,
					fontWeight: '700',
					[$theme.mediaQuery.medium]: {
						...$theme.typography.ParagraphLarge,
						maxWidth: '520px',
						fontWeight: '700',
					},
				}),
			},
		}}
	>
		{children}
	</ParagraphLarge>
)
const Zwerl: FC<ZwerlProps> = props => (
	<Container>
		<Block marginLeft="auto" marginRight="auto" marginBottom="scale800">
			<ZwerlLogo />
		</Block>

		<ZwerlTitle>{props.title}</ZwerlTitle>
		<ZwerlDescription>{props.description}</ZwerlDescription>
		<ZwerlBottomText>{props.bottomText}</ZwerlBottomText>
	</Container>
)

export default Zwerl
