import { colors } from 'baseui/tokens'

import primitiveColors from './primitiveColors'
import colorTokens from './tokens'

const semanticColors = {
	// Background
	backgroundPrimary: colorTokens.snow,
	backgroundSecondary: primitiveColors.positive,
	backgroundInversePrimary: colors.black,
	backgroundInverseSecondary: colors.black,
	notificationNegativeBackground: colors.red100,
	notificationNegativeText: colors.red600,

	// Border
	border: colorTokens.black,
	inputBorder: colorTokens.smoke,

	// Content
	contentPrimary: colorTokens.black,
	contentSecondary: '',

	// Buttons
	buttonPrimaryFill: primitiveColors.primary,
	buttonPrimaryText: colorTokens.black,
	buttonPrimaryHover: primitiveColors.primary,
	buttonSecondaryFill: colorTokens.sage,
	buttonSecondaryText: colorTokens.black,
	buttonTertiaryFill: colorTokens.snow,
	buttonTertiaryText: colorTokens.black,

	// Font Color
	colorPrimary: colorTokens.black,
	colorSecondary: colorTokens.snow,

	// Links
	linkText: primitiveColors.primary,
	linkVisited: primitiveColors.primary700,
	linkHover: primitiveColors.primary600,
	linkActive: primitiveColors.primary500,

	// Zwerl
	zwerl: '#32CDFF',

	// Twitter banner
	twitter: '#449df0',
}

export default semanticColors
