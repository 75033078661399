import { makeCMSComponent } from '../CMSComponent'

import { default as SectionHeaderImageComponent } from './SectionHeaderImage'

export const SectionHeaderImage = makeCMSComponent(
	{
		tag: 'SectionHeaderImage',
		label:
			'SectionHeaderImage - Is used with section headings that need image icons in the text',
		fields: {
			src: {
				label: 'URL to the image',
				widget: 'string',
			},
		},
	},
	SectionHeaderImageComponent
)
