import { makeCMSComponent } from '../CMSComponent'

import FormComponent from './Form'
import SubmitButton from './SubmitButton'

export { FormComponent, SubmitButton }

export const Form = makeCMSComponent(
	{
		tag: 'Form',
		label: 'Form',
		fields: {
			name: {
				label: 'Name',
				widget: 'string',
			},
			fields: {
				label: 'Fields',
				widget: 'mdx',
			},
			errorMessage: {
				label: 'Form error message',
				widget: 'string',
			},
			successMessage: {
				label: 'Form success message',
				widget: 'string',
			},
			successActionType: {
				label: 'Type of action after form submission success',
				widget: 'string',
			},
			submitText: {
				label: 'Text on submission button',
				widget: 'string',
			},
		},
	},
	FormComponent
)

export const FormSubmitButton = makeCMSComponent(
	{
		tag: 'FormSubmitButton',
		label: 'Form - Submit button',
		fields: {
			text: {
				label: 'Button text',
				widget: 'string',
			},
			$fullWidth: {
				label: 'if true it takes full width of container',
				widget: 'boolean',
			},
		},
	},
	SubmitButton
)

export default Form
