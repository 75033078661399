import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Field, FieldInputProps, FormikProps } from 'formik'
import { FC, useMemo } from 'react'

import {
	inputStyleOverrides,
	formControlStyleOverrides,
} from './style-override'

interface TextInputInternalProps {
	field: FieldInputProps<string>
	form: FormikProps<any>
	label?: string
	caption?: string
}

const TextInputInternalComponent: FC<TextInputInternalProps> = ({
	field,
	form,
	...props
}) => (
	<FormControl
		label={props.label}
		caption={props.caption}
		overrides={formControlStyleOverrides}
		error={form.touched[field.name] ? form.errors[field.name] : ''}
	>
		<Input
			{...field}
			{...props}
			error={!!form.errors[field.name] && !!form.touched[field.name]}
			overrides={inputStyleOverrides}
			value={field.value || ''}
		/>
	</FormControl>
)

export interface TextInputProps {
	name: string
	label: string
	caption?: string
	placeholder?: string
	required: boolean
	pattern?: string
	errorMessage?: string
}

function createValidator(
	pattern?: string,
	errorMessage?: string
): (value: string) => string | undefined {
	// Returns a validator which always return undefined (always valid)
	if (!pattern && !errorMessage) {
		return (_: string) => undefined
	}

	const regPattern = new RegExp(pattern!, 'i')

	const validate = (value: string) => {
		let err

		if (!regPattern.test(value)) {
			err = errorMessage
		}
		return err
	}

	return validate
}

export const TextInput: FC<TextInputProps> = ({
	required,
	pattern,
	errorMessage,
	...props
}) => {
	if ((pattern && !errorMessage) || (!pattern && errorMessage)) {
		throw new Error(
			'Pattern and errorMessage props should be set together, one is missing'
		)
	}

	const validator = useMemo(
		() => createValidator(pattern, errorMessage),
		[pattern, errorMessage]
	)

	return (
		<Field
			required={required}
			validate={validator}
			placeholder={props.placeholder}
			component={TextInputInternalComponent}
			{...props}
		/>
	)
}

export interface EmailInputProps {
	name: string
	label: string
	placeholder: string
	errorMessage: string
	required: boolean
}

export const EmailInput: FC<EmailInputProps> = props => (
	<TextInput {...props} pattern="^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$" />
)
