import { BlockOverrides } from 'baseui/block'
import { FC, useState, useEffect, memo } from 'react'

import { SIZE, SHAPE, KIND, Button } from '../../Button'
import DownChevron from '../../SVGS/DownChevron'
import TwitterPlusSphere from '../../SVGS/TwitterPlusSphere'
import { H2 } from '../../Typography'
import {
	ScrollToSphereContainer,
	TwitterFullPageBannerContainer,
	ScrollToSphereText,
	DownArrowAnimation,
	TwitterSphereAnimatedIconContainer,
	TwitterFullPageBannerInner,
	BannerTextAnimation,
	CtaAnimation,
} from '../styled-components'

export interface TwitterFullPageBannerProps {
	ctaText: string
	ctaUrl: string
}

const twitterBannerHeadingTypo = {
	fontWeight: '600',
	fontSize: '5.5rem',
	lineHeight: '6rem',
}

const ScrollToSphere = () => (
	<ScrollToSphereContainer>
		<ScrollToSphereText>
			<strong>Scroll</strong> to find out about Sphere
		</ScrollToSphereText>
		<DownArrowAnimation>
			<DownChevron />
		</DownArrowAnimation>
	</ScrollToSphereContainer>
)

const bannerHeaderOverrides: BlockOverrides = {
	Block: {
		style({ $theme }) {
			return {
				...$theme.typography.HeadingMedium,
				color: $theme.colors.colorSecondary,

				marginLeft: 'auto',
				marginRight: 'auto',
				marginTop: '0',

				[$theme.mediaQuery.small]: {
					maxWidth: '220px',
				},
				// TODO: Fix the hardcoded media query
				// Bigger width than iphone
				'@media screen and (min-width: 415px)': {
					maxWidth: '320px',
				},
				[$theme.mediaQuery.medium]: {
					...$theme.typography.HeadingXLarge,
					maxWidth: '600px',
				},
				// TODO: Remove snowflake typography definition
				[$theme.mediaQuery.large]: {
					...twitterBannerHeadingTypo,
					maxWidth: '800px',
				},
			}
		},
	},
}
const TwitterFullPageBanner: FC<TwitterFullPageBannerProps> = ({
	ctaText,
	ctaUrl,
}) => {
	const [bannerHeight, setBannerHeight] = useState<string>('100vh')

	useEffect(() => {
		setBannerHeight(`${window.innerHeight}px`)
	}, [setBannerHeight])

	return (
		<TwitterFullPageBannerContainer style={{ height: bannerHeight }}>
			<TwitterFullPageBannerInner>
				<TwitterSphereAnimatedIconContainer>
					<TwitterPlusSphere />
				</TwitterSphereAnimatedIconContainer>
				<BannerTextAnimation>
					<H2 overrides={bannerHeaderOverrides}>Sphere has joined Twitter!</H2>
				</BannerTextAnimation>

				<CtaAnimation>
					<Button
						$as="a"
						href={ctaUrl}
						kind={KIND.tertiary}
						data-tracking
						size={SIZE.default}
						shape={SHAPE.pill}
					>
						{ctaText}
					</Button>
				</CtaAnimation>
				<ScrollToSphere />
			</TwitterFullPageBannerInner>
		</TwitterFullPageBannerContainer>
	)
}

export default memo(TwitterFullPageBanner)
