import { FlexGridItem } from 'baseui/flex-grid'
import { Children, cloneElement, useMemo, ReactElement } from 'react'

interface HeroMediaProps {
	onLoad?(): void
}

export const HeroMedia: React.FC<HeroMediaProps> = ({
	children,
	onLoad = () => {},
}) => {
	const mediaContent = useMemo(
		() =>
			Children.toArray(children).map(child =>
				cloneElement(child as ReactElement, {
					onLoad,
				})
			),
		[children, onLoad]
	)

	return (
		<FlexGridItem
			overrides={{
				Block: {
					style: () => ({
						textAlign: 'center',
					}),
				},
			}}
		>
			{mediaContent}
		</FlexGridItem>
	)
}

export default HeroMedia
