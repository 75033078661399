import { makeCMSComponent } from '../CMSComponent'

import { FormAnswer as FormAnswerComponent } from './FormAnswer'
import { FormQuestion as FormQuestionComponent } from './FormQuestion'
import { default as QuestionFormComponent } from './QuestionForm'

export const QuestionForm = makeCMSComponent(
	{
		tag: 'QuestionForm',
		label: 'Form with questions',
		fields: {
			name: {
				label: 'Name of the form (this should map to the use case)',
				widget: 'string',
			},
		},
	},
	QuestionFormComponent
)

export const FormQuestion = makeCMSComponent(
	{
		tag: 'FormQuestion',
		label: 'FormQuestion - A question in the question form',
		fields: {
			type: {
				label: 'Type of question',
				widget: 'select',
				options: ['checkboxes', 'multiple-choice', 'short-answer'],
			},
			label: {
				label: 'Text above the question title',
				widget: 'string',
			},
			title: {
				label: 'Question title (actual question content)',
				widget: 'string',
			},
			answers: {
				label: 'Possible Answers',
				widget: 'mdx',
			},
			inputName: {
				label: 'Optional form input name (by default title is used)',
				widget: 'string',
			},
			required: {
				label: 'Should the question be answered?',
				widget: 'boolean',
			},
		},
	},
	FormQuestionComponent
)

export const FormAnswer = makeCMSComponent(
	{
		tag: 'FormAnswer',
		label: 'FormAnswer - An answer option for question',
		fields: {
			label: {
				label: 'Label or text of the answer',
				widget: 'string',
			},
			value: {
				label: 'Value of the answer used as form input value',
				widget: 'string',
			},
		},
	},
	FormAnswerComponent
)
