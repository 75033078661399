import { useEffect, useCallback } from 'react'

import { EventNames } from '../EventNames'
import { useTracking } from '../hooks'

const TRACKING_DATA_ATTRIBUTE_SELECTOR = '[data-tracking="true"]'

function isLinkExternal(el: HTMLAnchorElement): boolean {
	return el.host !== window.location.host || el.target === '_blank'
}

export function useClickTracker(): void {
	const { trackEvent } = useTracking()

	const clickTrackerHandler = useCallback(
		(event: MouseEvent) => {
			const targetEl = event.target as HTMLElement

			// closest also matches the element itself
			if (targetEl.closest(TRACKING_DATA_ATTRIBUTE_SELECTOR)) {
				if (targetEl.tagName === 'A') {
					const anchorEl = targetEl as HTMLAnchorElement
					const linkIsInternal = !isLinkExternal(anchorEl)

					if (linkIsInternal) {
						event.preventDefault()
					}

					trackEvent(EventNames.LinkClicked, {
						link_url: anchorEl.href,
					})

					if (linkIsInternal) {
						window.location.href = anchorEl.href
					}
				} else if (
					targetEl.tagName === 'BUTTON' ||
					targetEl.getAttribute('role') === 'button'
				) {
					trackEvent(EventNames.ButtonClicked, {
						name: targetEl.textContent as string,
						// TODO: Change `nav` to something more reliable
						location: targetEl.closest('nav') ? 'top' : 'in_page',
					})
				}
			}
		},
		[trackEvent]
	)

	useEffect(() => {
		document.addEventListener('click', clickTrackerHandler)
		return () => document.removeEventListener('click', clickTrackerHandler)
	}, [clickTrackerHandler])
}
