import { forwardRef } from 'react'

interface AnswerProps {
	text: string
}

const Answer = forwardRef<HTMLDivElement, AnswerProps>(({ text }, ref) => {
	const answerTextLines = text.trim().split('\n')

	if (answerTextLines.length === 1) {
		return <div ref={ref}>{text}</div>
	}

	return (
		<div ref={ref}>
			<ul>
				{answerTextLines.map((line: string, index: number) => (
					<li key={index}>{line}</li>
				))}
			</ul>
		</div>
	)
})

export default Answer
