import { Radio as BaseUIRadio } from 'baseui/radio'
import { Field, FieldInputProps, FormikProps } from 'formik'

import { radioStyleOverrides } from './style-override'

interface RadioInternalProps {
	field: FieldInputProps<string>
	form: FormikProps<any>
	label?: string
	value?: string
	caption?: string
}

export interface RadioProps {
	name: string
	value?: string
	caption?: string
	required?: boolean
}

const RadioInternalComponent: React.FC<RadioInternalProps> = ({
	field,
	form,
	children,
	...props
}) => (
	<BaseUIRadio
		{...field}
		{...props}
		value={props.value || ''}
		error={!!form.errors[field.name] && !!form.touched[field.name]}
		overrides={radioStyleOverrides}
	>
		{children}
	</BaseUIRadio>
)

const Radio: React.FC<RadioProps> = props => (
	<Field component={RadioInternalComponent} {...props} />
)

export default Radio
