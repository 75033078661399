import { FetchManager } from '@sphere/fetch-manager'

import { JoinWaitlist } from '../../domain/JoinWaitlist'
import {
	JoinWaitlistRepo as JoinWaitlistRepoInterface,
	JoinWaitlistFetchResponse,
} from '../../domain/repositories/JoinWaitlistRepo'

interface JoinWaitlistDTO {
	email: string
	name: string
}

// Maps the domain to data transfer object
function toDTO(data: JoinWaitlist): JoinWaitlistDTO {
	return {
		email: data.email,
		name: data.firstName,
	}
}

export class JoinWaitlistRepo implements JoinWaitlistRepoInterface {
	static joinWaitlistApiPath = '/api/hardWaitlist/join/v2'

	constructor(
		private _baseApiUrl: string,
		private _fetchManager: FetchManager = new FetchManager()
	) {}

	async create(joinWaitlist: JoinWaitlist): Promise<JoinWaitlistFetchResponse> {
		const dto = toDTO(joinWaitlist)
		const url = `${this._baseApiUrl}${JoinWaitlistRepo.joinWaitlistApiPath}`

		return this._fetchManager.post(
			url,
			dto
		) as Promise<JoinWaitlistFetchResponse>
	}
}
