import { ParagraphLarge } from 'baseui/typography'
import React, {
	Children,
	ReactElement,
	useMemo,
	useContext,
	useCallback,
	useEffect,
} from 'react'

import { EventNames, useTracking } from '../analytics'
import {
	JoinWaitlistActions,
	JoinWaitlistContext,
} from '../context/JoinWaitlistProvider'
import { FullPageModal, FullPageModalBody } from '../Modal'

import { subtitleParagraphOverrides } from './style-override'

export interface JoinWaitlistFollowUpModalProps {
	name: string
	subtitle: string
}

const JoinWaitlistFollowUpFormModal: React.FC<JoinWaitlistFollowUpModalProps> =
	({ children, subtitle }) => {
		const { formSubmitted, followUpSubmitted, dispatch } =
			useContext(JoinWaitlistContext)

		const followUpFormPending = useMemo(
			() => formSubmitted && !followUpSubmitted,
			[formSubmitted, followUpSubmitted]
		)

		const handleClose = useCallback(() => {
			dispatch!({ type: JoinWaitlistActions.Reset })
		}, [dispatch])

		const [modalHeader, modalContent] = useMemo(() => {
			const childrenArray = Children.toArray(children)

			// TODO: Find a better way like using child.type
			const header = childrenArray.find(
				child =>
					(child as ReactElement).props.mdxType ===
					'WaitlistFollowUpModalHeader'
			)

			childrenArray.splice(childrenArray.indexOf(header!), 1)

			return [header, childrenArray]
		}, [children])

		const { trackEvent } = useTracking({
			once: [EventNames.WaitlistQuestionsViewed],
		})

		useEffect(() => {
			// Tracking when modal is opened first time
			if (followUpFormPending) {
				trackEvent(EventNames.WaitlistQuestionsViewed)
			}
		}, [followUpFormPending, trackEvent])

		return (
			<FullPageModal onClose={handleClose} isOpen={followUpFormPending}>
				{modalHeader}
				<FullPageModalBody>
					<>
						<ParagraphLarge overrides={subtitleParagraphOverrides}>
							{subtitle}
						</ParagraphLarge>
						{modalContent}
					</>
				</FullPageModalBody>
			</FullPageModal>
		)
	}

export default JoinWaitlistFollowUpFormModal
