import { Checkbox as BaseUICheckbox } from 'baseui/checkbox'
import {
	Field,
	FieldInputProps,
	FieldValidator,
	FormikProps,
	useFormikContext,
} from 'formik'
import {
	Children,
	cloneElement,
	ReactElement,
	useCallback,
	useMemo,
	useState,
} from 'react'
import styled from 'styled-components'

import { checkboxStyleOverrides } from './style-override'

interface CheckboxInternalProps {
	field: FieldInputProps<string>
	form: FormikProps<any>
	label?: string
	value?: string
	caption?: string
}

export interface CheckboxProps {
	name: string
	value?: string
	caption?: string
	required?: boolean
	errorMessage?: string
	validate?: FieldValidator
}

export interface CheckboxGroupProps {
	name: string
	caption?: string
	label?: string
	required?: boolean
	errorMessage?: string
}

const CheckboxInternalComponent: React.FC<CheckboxInternalProps> = ({
	field,
	form,
	children,
	...props
}) => {
	const [checked, setChecked] = useState<boolean>(false)
	const handleChange = useCallback(
		e => {
			field.onChange(e)
			setChecked((e.target as HTMLFormElement).checked)
		},
		[field, setChecked]
	)

	return (
		<BaseUICheckbox
			{...field}
			{...props}
			checked={checked}
			value={props.value || ''}
			onChange={handleChange}
			overrides={checkboxStyleOverrides}
			error={!!form.errors[field.name] && !!form.touched[field.name]}
		>
			{children}
		</BaseUICheckbox>
	)
}

type Validator = () => string | void

function createValidator(
	required?: boolean,
	fieldValueLength?: undefined | number
): Validator {
	const alwaysValid = () => undefined
	const alwaysInvalid = () => 'value is required'

	if (!required) {
		return alwaysValid
	}

	return typeof fieldValueLength === 'number' && fieldValueLength > 0
		? alwaysValid
		: alwaysInvalid
}

const Checkbox: React.FC<CheckboxProps> = ({
	required,
	errorMessage,
	validate,
	...props
}) => (
	<Field validate={validate} component={CheckboxInternalComponent} {...props} />
)

const CheckboxGroupStyled = styled.div.attrs({
	role: 'group',
	'aria-describedby': 'checkbox',
})`
	display: flex;
	flex-direction: column;
`
export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
	required,
	children,
	name,
}) => {
	const { values } = useFormikContext()

	const currentFieldValue = (values as any)[name]

	const validate = useMemo(
		() => createValidator(required, currentFieldValue?.length),
		[required, currentFieldValue?.length]
	)
	return (
		<CheckboxGroupStyled>
			{Children.map(children, checkbox =>
				cloneElement(checkbox as ReactElement, {
					validate,
				})
			)}
		</CheckboxGroupStyled>
	)
}

export default Checkbox
