import { StyledLink, LinkProps as StyledLinkProps } from 'baseui/link'
import * as React from 'react'

export interface LinkProps extends StyledLinkProps {
	text: string
	url: string
}

const Link: React.FC<LinkProps> = ({ text, url, ...props }) => (
	<StyledLink
		$style={{
			':active': { textDecoration: 'underline' },
		}}
		{...props}
		href={url}
	>
		{text}
	</StyledLink>
)

export default Link
