import { FC } from 'react'

const AppleIcon: FC = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="24" height="24" fill="white" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.1906 5.92218C14.7777 5.16439 15.2227 4.09331 15.0617 3C14.1023 3.06668 12.9808 3.68054 12.3264 4.48067C11.7299 5.20566 11.2397 6.28415 11.4311 7.33089C12.48 7.3637 13.5626 6.73502 14.1906 5.92218Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.375 16.2064C18.9552 17.142 18.7532 17.5601 18.2125 18.3888C17.4582 19.5456 16.3946 20.9861 15.0753 20.9967C13.9044 21.0094 13.6024 20.2293 12.0128 20.2389C10.4232 20.2473 10.0918 21.0115 8.91873 20.9998C7.60052 20.9882 6.59266 19.6885 5.83834 18.5317C3.72795 15.2994 3.50596 11.5051 4.80734 9.48678C5.73314 8.05373 7.19338 7.2155 8.56524 7.2155C9.9613 7.2155 10.8398 7.986 11.996 7.986C13.1174 7.986 13.8002 7.21338 15.4151 7.21338C16.6376 7.21338 17.9326 7.88333 18.8542 9.03908C15.8328 10.705 16.322 15.0454 19.375 16.2064Z"
			fill="black"
		/>
	</svg>
)

export default AppleIcon
