import { CheckboxOverrides } from 'baseui/checkbox'
import { FormControlOverrides } from 'baseui/form-control'

export const checkboxStyleOverrides: CheckboxOverrides = {
	Root: {
		style({ $theme }) {
			return {
				marginBottom: $theme.sizing.scale600,
			}
		},
	},
	Checkmark: {
		style({ $theme, $error }) {
			return {
				borderLeftWidth: '1px',
				backgroundSize: '90%',
				backgroundPositionX: '1px',
				borderRightWidth: '1px',
				borderBottomWidth: '1px',
				borderTopWidth: '1px',
				borderTopStyle: 'solid',
				borderLeftStyle: 'solid',
				borderRightStyle: 'solid',
				borderBottomStyle: 'solid',
				borderTopColor: !$error
					? $theme.colors.border
					: $theme.colors.tickFillErrorSelected,
				borderLeftColor: !$error
					? $theme.colors.border
					: $theme.colors.tickFillErrorSelected,
				borderRightColor: !$error
					? $theme.colors.border
					: $theme.colors.tickFillErrorSelected,
				borderBottomColor: !$error
					? $theme.colors.border
					: $theme.colors.tickFillErrorSelected,
			}
		},
	},
}

export const formControlStyleOverrides: FormControlOverrides = {
	ControlContainer: {
		style: ({ $theme }) => ({
			marginBottom: $theme.sizing.scale400,
			[$theme.mediaQuery.medium]: {
				marginBottom: $theme.sizing.scale600,
			},
		}),
	},
}
