import { StatelessAccordion } from 'baseui/accordion'
import { Key, useCallback, useState } from 'react'
import styled from 'styled-components'

import useTrackSectionViewed from '../hooks/useTrackSectionViewed'
import { PageSection } from '../PageSection'
import { H2 } from '../Typography'

import { sectionHeaderOverrides } from './style-override'

interface FAQProps {
	title: string
	questions: string
	expandedQuestionKey: string
}

const FAQSection = styled(PageSection).attrs(() => ({
	$color: 'mono300',
}))`
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
`

const FAQS: React.FC<FAQProps> = ({ title, children, expandedQuestionKey }) => {
	const [expanded, setExpanded] = useState<Key[]>([expandedQuestionKey])
	const { ref } = useTrackSectionViewed({
		sectionName: 'our-app/FAQs',
	})
	const handleAccordionChange = useCallback(
		({ expanded }) => {
			setExpanded(expanded)
		},
		[setExpanded]
	)

	return (
		<FAQSection ref={ref}>
			<H2 overrides={sectionHeaderOverrides}>{title}</H2>
			<StatelessAccordion
				renderAll
				expanded={expanded}
				onChange={handleAccordionChange}
			>
				{children}
			</StatelessAccordion>
		</FAQSection>
	)
}

export default FAQS
