import React, { Children, cloneElement, ReactElement } from 'react'

import { CheckboxGroup } from '../../Checkbox'
import { CheckboxGroupProps } from '../../Checkbox/Checkbox'
import { FormInputText, FormInputProps } from '../../FormInput'
import { RadioGroup, RadioGroupProps } from '../../RadioGroup'
import { HeadingSmall, Label2 } from '../../Typography'

import { QuestionContainer, QuestionImage } from './styled-components'

export interface FormQuestionParams {
	type: 'checkboxes' | 'multiple-choice' | 'short-answer'
	label: string
	title: string
	answers: string
	required: boolean
	inputName: string
	imageUrl?: string
}
interface TypeToFormComponent {
	'multiple-choice': React.ComponentType<RadioGroupProps>
	'short-answer': React.ComponentType<FormInputProps>
	checkboxes: React.ComponentType<CheckboxGroupProps>
}
const typeToFormComponentMap: TypeToFormComponent = {
	'multiple-choice': RadioGroup,
	'short-answer': FormInputText,
	checkboxes: CheckboxGroup,
}

// TODO: Find a way to not use `string` for type prop
const FormQuestion: React.FC<
	Omit<FormQuestionParams, 'type'> & { type: string }
> = ({
	title,
	type,
	label,
	imageUrl,
	children,
	required = false,
	inputName = '',
}) => {
	const FormControlComponent =
		typeToFormComponentMap[type as FormQuestionParams['type']]

	return (
		<QuestionContainer>
			<Label2
				overrides={{
					Block: {
						style({ $theme }) {
							return {
								color: $theme.colors.mono500,
							}
						},
					},
				}}
			>
				{label}
			</Label2>
			<HeadingSmall
				overrides={{
					Block: {
						style({ $theme }) {
							return {
								marginTop: $theme.sizing.scale300,
								marginBottom: $theme.sizing.scale900,
							}
						},
					},
				}}
			>
				{title}
			</HeadingSmall>
			{imageUrl && <QuestionImage src={imageUrl} />}
			<FormControlComponent
				label={''}
				name={inputName !== '' ? inputName : title}
				required={required}
			>
				{children &&
					Children.map(children, child => {
						const answerItem = child as ReactElement<any>
						let additionalProps: {
							type: FormQuestionParams['type']
							name: string
							required?: boolean
						} = {
							type: type as FormQuestionParams['type'],
							name: title,
						}

						if (FormControlComponent === CheckboxGroup) {
							additionalProps = {
								...additionalProps,
								required,
							}
						}
						return cloneElement(answerItem, additionalProps)
					})}
			</FormControlComponent>
		</QuestionContainer>
	)
}

export default FormQuestion
