export default function standardizedFetch(
	url: string,
	requestInit?: RequestInit
) {
	return fetch(url, {
		// The default value for credentials has changed over time
		// Set to same-origin (the current default) for consistency
		credentials: 'same-origin',
		...requestInit,
	})
}
