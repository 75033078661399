import { StatefulPanel } from 'baseui/accordion'
import { useCallback, useRef } from 'react'

import Answer from './Answer'
import { questionStyleOverrides } from './style-override'

interface QuestionProps {
	question: string
	answer: string
}

const Question: React.FC<QuestionProps> = ({
	question,
	answer,
	...otherProps
}) => {
	const answerRef = useRef<HTMLDivElement>(null)

	const handleExpanded = useCallback(({ expanded }) => {
		if (expanded && answerRef.current) {
			answerRef.current!.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			})
		}
	}, [])

	return (
		<StatefulPanel
			{...otherProps}
			title={question}
			overrides={questionStyleOverrides}
			onChange={handleExpanded}
		>
			<Answer ref={answerRef} text={answer} />
		</StatefulPanel>
	)
}

export default Question
