import { FC } from 'react'

const DesktopIcon: FC = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="24" height="24" fill="white" />
		<path
			d="M3.4668 6.26472C3.4668 5.56623 4.03303 5 4.73152 5H19.2687C19.9672 5 20.5335 5.56624 20.5335 6.26472V16.7333H3.4668V6.26472Z"
			fill="black"
		/>
		<rect
			x="1.3335"
			y="18.8667"
			width="21.3333"
			height="2.13333"
			rx="1.06667"
			fill="black"
		/>
	</svg>
)

export default DesktopIcon
