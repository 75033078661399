import { SegmentClient } from '@sphere/segment-proxy'

import { EventNames } from '../analytics'
import { JoinWaitlist } from '../domain/JoinWaitlist'
import getConfig from '../infra/config/config'
import { JoinWaitlistRepo } from '../infra/repositories/JoinWaitlistRepo'

const EMAIL_ALREADY_EXISTS_ERRORS = [
	'Email is already on waitlist.',
	'Email already in use.',
]

export default class JoinWaitlistUseCase {
	constructor(private _joinWaitlistRepository: JoinWaitlistRepo) {}

	async execute(waitlistData: JoinWaitlist) {
		const { error, status, data } = await this._joinWaitlistRepository.create(
			waitlistData
		)

		SegmentClient.track(EventNames.JoinWaitlistEmailSubmitted)

		if (error && status !== 200 && typeof data === 'object') {
			return {
				error: this._shouldReturnError(
					'error' in (data as any) ? data.error : error,
					status
				)
					? error
					: undefined,
				data,
				status,
			}
		}

		return { error, status, data }
	}

	private _shouldReturnError(error: string, status: number): Boolean {
		const isEmailAlreadyJoined =
			status === 409 && error && EMAIL_ALREADY_EXISTS_ERRORS.includes(error)

		return !isEmailAlreadyJoined
	}
}

export function createJoinWaitlistUsecase() {
	const apiBaseUrl = getConfig().apiBaseUrl!

	return new JoinWaitlistUseCase(new JoinWaitlistRepo(apiBaseUrl))
}
