import { makeCMSComponent } from '../CMSComponent'

import { default as LinkComponent } from './Link'

export { LinkComponent }

export const Link = makeCMSComponent(
	{
		tag: 'Link',
		label: 'Link',
		fields: {
			text: {
				label: 'Text',
				widget: 'string',
			},
			url: {
				label: 'Link URL',
				widget: 'string',
			},
		},
	},
	LinkComponent
)
