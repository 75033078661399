import React, { useMemo, useContext, useCallback } from 'react'

import {
	JoinWaitlistActions,
	JoinWaitlistContext,
} from '../context/JoinWaitlistProvider'
import { SuccessModal } from '../SuccessModal'

export interface JoinWaitlistFollowUpSuccessModalProps {
	name: string
	subtitle: string
	buttonText: string
}

const JoinWaitlistFollowUpSuccessModal: React.FC<JoinWaitlistFollowUpSuccessModalProps> =
	({ children, buttonText, subtitle }) => {
		const { formSubmitted, followUpSubmitted, dispatch } =
			useContext(JoinWaitlistContext)

		const isModalShown = useMemo(
			() => !!formSubmitted && !!followUpSubmitted,
			[formSubmitted, followUpSubmitted]
		)

		const handleClose = useCallback(() => {
			dispatch!({ type: JoinWaitlistActions.Reset })
		}, [dispatch])

		return (
			<SuccessModal
				onClose={handleClose}
				isOpen={isModalShown}
				subtitle={subtitle}
				buttonText={buttonText}
			>
				{children}
			</SuccessModal>
		)
	}

export default JoinWaitlistFollowUpSuccessModal
