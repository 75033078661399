import { BlockProps } from 'baseui/block'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { forwardRef, PropsWithChildren } from 'react'
import styled from 'styled-components'

import { SHAPE, SIZE } from '../Button'
import { ToggleButton, ToggleButtonProps } from '../ToggleButton'
import { H1, HeadingXSmall } from '../Typography'

interface HeroIconImageProps {
	component: React.ComponentType
}

// See https://baseweb.design/blog/responsive-web#flexgrid-component
export const HeroContainer = forwardRef<HTMLDivElement, PropsWithChildren<{}>>(
	({ children }, ref) => (
		<FlexGrid
			ref={ref}
			flexGridColumnCount={[1, 1, 2]}
			flexDirection={['column', 'column', 'row']}
			flexGridColumnGap="scale800"
			flexGridRowGap="scale800"
			overrides={{
				Block: {
					style: ({ $theme }) => ({
						flexWrap: 'nowrap',
						borderBottomWidth: '1px',
						borderBottomColor: $theme.colors.border,
						borderBottomStyle: 'solid',
						position: 'relative',
						overflowX: 'hidden',
					}),
				},
			}}
			paddingLeft={['scale800', 'scale800', 'scale1800']}
			paddingRight={['scale800', 'scale800', 'scale1800']}
			paddingTop={['scale400', 'scale400', 'scale400']}
			paddingBottom={['scale400', 'scale800', 'scale3200']}
		>
			{children}
		</FlexGrid>
	)
)

export const HeroTitle: React.FC<BlockProps> = ({ children }) => (
	<H1
		overrides={{
			Block: {
				style: ({ $theme }) => ({
					marginTop: '0',
					...$theme.typography.HeadingMedium,
					[$theme.mediaQuery.medium]: {
						...$theme.typography.HeadingXLarge,
						marginTop: $theme.sizing.scale600,
						marginBottom: $theme.sizing.scale1800,
						maxWidth: '532px',
					},
					[$theme.mediaQuery.large]: {
						...$theme.typography.HeadingXXLarge,
						marginBottom: $theme.sizing.scale3200,
						maxWidth: '600px',
					},
				}),
			},
		}}
	>
		{children}
	</H1>
)

export const HeroSubtitle: React.FC<BlockProps & { $hasMarginLeft: boolean }> =
	({ children, $hasMarginLeft }) => (
		<HeadingXSmall
			overrides={{
				Block: {
					style: ({ $theme }) => ({
						marginTop: $theme.sizing.scale800,
						marginBottom: $theme.sizing.scale800,
						marginLeft: 0,
						fontWeight: 400,
						[$theme.mediaQuery.large]: {
							marginLeft: $hasMarginLeft ? $theme.sizing.scale800 : 0,
							maxWidth: '600px',
						},
					}),
				},
			}}
		>
			{children}
		</HeadingXSmall>
	)

export const HeroAction = styled.div<{ $isAlwaysShown: boolean }>`
	display: ${props => (props.$isAlwaysShown ? 'flex' : 'none')};
	flex-direction: column;
	align-items: flex-start;

	${props => props.theme.mediaQuery.small} {
		display: ${props => (props.$isAlwaysShown ? 'flex' : 'none')};
	}
	${props => props.theme.mediaQuery.medium} {
		display: flex;
	}

	${props => props.theme.mediaQuery.large} {
		flex-direction: row;
		align-items: center;
	}
`

export const StyledHeroIcon: React.FC = styled.div`
	display: inline-block;
	margin-left: ${props => props.theme.sizing.scale100};
	vertical-align: middle;
`

export const HeroImage = styled.img`
	max-width: 100%;
`

export const HeroIconImage: React.FC<HeroIconImageProps> = ({ component }) => {
	const IconComponent = component

	return (
		<StyledHeroIcon>
			<IconComponent />
		</StyledHeroIcon>
	)
}

export const HeroButton: React.FC<ToggleButtonProps> = ({
	children,
	...props
}) => (
	<ToggleButton size={SIZE.large} shape={SHAPE.pill} {...props}>
		{children}
	</ToggleButton>
)

export const HeroContent: React.FC = ({ children }) => (
	<FlexGridItem>{children}</FlexGridItem>
)
