import styled from 'styled-components'

interface Header {
	$showBorderBottom: boolean
	$hasPaddingTop?: boolean
}

// This is calculated based on the fact that when twitter banner is shown and page
// scrolls for each 1px scroll of the page the banner transformY changes 2px
const POSITIONED_HEADER_PADDING_TOP = '33.33vh'

export const StickyHeader = styled.header<Header>`
	position: sticky;
	z-index: ${({ theme }) => theme.zIndex.stickyHeader};
	top: 0;
	border-bottom: ${({ theme, $showBorderBottom }) =>
		$showBorderBottom
			? `1px solid ${theme.colors.mono500}`
			: '1px solid transparent'};
`

export const PositionedHeader = styled.header<Header>`
	padding-top: ${({ $hasPaddingTop }) =>
		$hasPaddingTop ? POSITIONED_HEADER_PADDING_TOP : '0'};
`
