import { useCallback } from 'react'
import styled from 'styled-components'

import { EventNames, useTracking } from '../analytics'
import { FormComponent } from '../Form/index'

export interface QuestionFormProps {
	name: string
}

type FormNameToTrackQuestionActioned = Record<
	QuestionFormProps['name'],
	EventNames
>

const QuestionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const formNameToTrackQuestionActioned: FormNameToTrackQuestionActioned = {
	'join-waitlist-follow-up': EventNames.WaitlistQuestionsActioned,
}

const QuestionForm: React.FC<QuestionFormProps> = ({ children, name }) => {
	const eventName = formNameToTrackQuestionActioned[name]

	const { trackEvent } = useTracking(
		eventName
			? {
					once: [eventName],
			  }
			: {}
	)
	const handleQuestionFormChange = useCallback(() => {
		if (eventName) {
			trackEvent(eventName)
		}
	}, [trackEvent, eventName])

	return (
		<FormComponent
			name={name}
			errorMessage="There was an error while submitting the form. Please try later"
			successMessage=""
			fields=""
			successActionType="None"
			submitText="Submit"
			onChange={handleQuestionFormChange}
		>
			<QuestionsContainer>{children}</QuestionsContainer>
		</FormComponent>
	)
}

export default QuestionForm
