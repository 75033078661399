import { makeCMSComponent } from '../CMSComponent'

import {
	TextInput,
	EmailInput,
	TextInputProps as FormInputProps,
} from './TextInput'

export const FormInputText = makeCMSComponent(
	{
		tag: 'FormInputText',
		label: 'Form - String',
		fields: {
			name: {
				label: 'Name',
				widget: 'string',
			},
			label: {
				label: 'Label',
				widget: 'string',
			},
			required: {
				label: 'Required',
				widget: 'boolean',
			},
		},
	},
	TextInput
)

export const FormInputEmail = makeCMSComponent(
	{
		tag: 'FormInputEmail',
		label: 'Form - Email',
		fields: {
			name: {
				label: 'Name',
				widget: 'string',
			},
			label: {
				label: 'Label',
				widget: 'string',
			},
			required: {
				label: 'Required',
				widget: 'boolean',
			},
			placeholder: {
				label: 'Placeholder',
				widget: 'string',
			},
			errorMessage: {
				label: 'Error Message',
				widget: 'string',
			},
		},
	},
	EmailInput
)

export { FormInputProps }
