import { Theme } from 'baseui/theme'

const breakpoints = {
	small: 320,
	medium: 800,
	large: 1280,
}

const ResponsiveTheme: Pick<Theme, 'breakpoints' | 'mediaQuery'> = Object.keys(
	breakpoints
).reduce<Pick<Theme, 'breakpoints' | 'mediaQuery'>>(
	(acc, key: string) => {
		acc.mediaQuery[
			key as keyof Theme['breakpoints']
		] = `@media screen and (min-width: ${
			breakpoints[key as keyof Theme['breakpoints']]
		}px)`
		return acc
	},
	{
		breakpoints,
		mediaQuery: {
			small: '',
			medium: '',
			large: '',
		},
	}
)

export default ResponsiveTheme
