import { makeCMSComponent } from '../CMSComponent'

import { default as VideoComponent } from './Video'

export const Video = makeCMSComponent(
	{
		tag: 'Video',
		label: 'Video',
		fields: {
			src: {
				label: 'Video source url',
				widget: 'string',
			},
		},
	},
	VideoComponent
)

export default Video
